<script setup lang="ts">
import {ExpandingView, MutableWork, SuggestedWork, ValidationError, Work} from '../../model/model';

import {Message, MessageType} from '../global/MessageView.vue';

import ConfirmService, {DialogMessage} from '../../services/ConfirmService';

import {Ref, ref} from 'vue';
import {emitter} from '../../main';
import WorkService from '../../services/WorkService';
import {asView, today} from '../../util/data';
import {clearErrorAt, setErrorAt} from '../../util/validationErrors';
import WorksListing from "../reactive/WorksListing.vue";

const workService = new WorkService()
const confirmService = new ConfirmService(emitter)

const props = defineProps<{
  carId: string,
  works: Array<Work> | null,
  editEnabled: Boolean
}>()

const expandableWorks = ref(asView(props.works ?? []))

const createdFor: Ref<SuggestedWork | null> = ref(null)
const createdWork: Ref<MutableWork | null> = ref(null)

function clearError(id: String | null) {
  const rowId = id ? `row-${id}-expanded` : 'new-row'
  clearErrorAt(`#works-data #${rowId}`)
}

function setError(id: String | null, error: ValidationError) {
  const rowId = id ? `row-${id}-expanded` : 'new-row'
  setErrorAt(`#works-data #${rowId}`, 'td', error)
}

function addNew(suggested: SuggestedWork | null) {
  createdFor.value = null
  const work = MutableWork.newFor(props.carId)
  work.name = suggested?.name
  work.components = suggested?.components ?? []
  work.date = today()
  work.kilometers = Math.max(0, ...expandableWorks.value.map((w: ExpandingView<Work>) => Number(w.data.kilometers)))
  createdWork.value = work
}

function cancelNew() {
  createdFor.value = null
  createdWork.value = null
  clearError(null)
}

async function saveNew() {
  const toCreate = createdWork.value
  if (toCreate !== null) {
    clearError(null)
    try {
      const newWork = await workService.createWork(toCreate)
      expandableWorks.value.push(new ExpandingView<Work>(newWork, false))
      createdWork.value = null
      
      const basedOn = createdFor.value
      if (basedOn) {
        emitter.emit("suggestion-added", basedOn)
      }
      createdFor.value = null
      
      emitter.emit("log", new Message("message.work.created.ok", MessageType.Success))
    } catch (e) {
      if (e instanceof ValidationError) {
        setError(null, e as ValidationError)
      } else {
        clearError(null)
      }

      console.log(e)
      emitter.emit("log", new Message("message.work.created.fail", MessageType.Error))
    }
  }
}

function confirmWorkDel(workId: String) {
  confirmService.askToConfirm(
    new DialogMessage("message.work.deleted.confirm", {}, "modal.cancel", "modal.delete"),
    () => delWork(workId)
  )
}

function delWork(workId: String) {
  workService.deleteWorkById(workId)
    .then(() => {
      const index = expandableWorks.value.findIndex((w: ExpandingView<Work>) => w.data.id === workId)
      if (index >= 0) {
        expandableWorks.value.splice(index, 1)
      }
      emitter.emit("log", new Message("message.work.deleted.ok", MessageType.Success))
    })
    .catch(() => emitter.emit("log", new Message("message.work.deleted.fail", MessageType.Error)))
}

function confirmWorkEdit(work: Work) {
  editWork(work)
}

async function editWork(work: Work) {
  clearError(work.id)
  try {
    const updated = await workService.updateWork(work)
    const index = expandableWorks.value.findIndex((w: ExpandingView<Work>) => w.data.id === work.id)
    if (index >= 0) {
      expandableWorks.value.at(index).data = updated
    }
    emitter.emit("log", new Message("message.work.created.ok", MessageType.Success))
  } catch (error) {
    if (error instanceof ValidationError) {
      setError(work.id, error as ValidationError)
    } else {
      clearError(work.id)
    }
    emitter.emit("log", new Message("message.work.created.fail", MessageType.Error))
  }
}
</script>

<template>
  <h1>{{ $t("car.works") }}</h1>
  <WorksListing :works="expandableWorks" :edit-enabled="editEnabled" :createdWork="createdWork" @delete-work="confirmWorkDel" @edit-work="confirmWorkEdit" />
  <button class="work-button action-button" v-if="editEnabled && !createdWork" @click="addNew(null)" :title="$t('car.action.newWork')"><font-awesome-icon class="nav-icon" icon="plus-circle" /></button>
  <button class="work-button action-button" v-if="createdWork" @click="saveNew" :title="$t('car.action.saveWork')"><font-awesome-icon class="nav-icon" icon="floppy-disk" /></button>
  <button class="work-button action-button" v-if="createdWork" @click="cancelNew" :title="$t('car.action.undoWork')"><font-awesome-icon class="nav-icon" icon="clock-rotate-left" /></button>
</template>

<style scoped>
th, td {
  font-size: large;
}
</style>

<style>
.nested-table th {
  vertical-align: baseline;
}
</style>