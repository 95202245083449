import { activeLang, baseUrl, emitter } from "../main";
import { AuthError, InvalidField, ValidationError } from "../model/model";
import { credentialStore } from "../stores/credentials";

function unauthed() {
    console.warn("Not logged in")
    const error = new AuthError(false)
    emitter.emit("auth-error", error)
    throw error
}

function adminHeader(): Headers {
    const jwt = credentialStore()?.anyJwt ?? unauthed()
    return new Headers({
        "Authorization": "Bearer " + jwt,
        "Accept-Language": `${activeLang()}, *`
    })
}

export function anonCaptchaHeaders(token: string): Headers {
    return new Headers({
        "X-Recaptcha-Response": token
    })
}

function buildPath(resource: string, queryParams: Record<string, any> | null): string {
    const qualified = baseUrl + resource
    if (queryParams == null) {
        return qualified
    } else {
        return qualified + "?" + new URLSearchParams(queryParams)
    }
}

async function wrapError(response: Response): Promise<any> {
    console.log("ERROR: " + JSON.stringify(response))
    if (response.status === 400) {
        let invalidFields: Array<InvalidField> 
        try {
            invalidFields = await response.json()
        } catch (e) {
            throw "Unknown bad request error"    
        }
        throw new ValidationError(invalidFields)
    } else if (response.status === 401) {
        const text = await response.text()
        const error = new AuthError(text === "Expired auth token passed")
        emitter.emit("auth-error", error)
        throw error
    } else {
        throw "Unknown error"
    }
}

async function wrappedFetchResponse(method: string, path: string, headers: Headers, params: Record<string, any> | null, body: any | null): Promise<Response> {
    return await fetch(buildPath(path, params), {
        method: method,
        headers: headers,
        body: body == null ? null : JSON.stringify(body)
    })
}

async function wrappedFetch<T>(method: string, path: string, headers: Headers, params: Record<string, any> | null, body: any | null): Promise<T> {
    const result = await wrappedFetchResponse(method, path, headers, params, body)
    if (result.ok) {
        return await result.json()
    } else {
        await wrapError(result)
    }
}

async function adminFetch<T>(method: string, path: string, params: Record<string, any> | null, body: any | null): Promise<T> {
    return await wrappedFetch(method, path, adminHeader(), params, body)
}

async function anonFetch<T>(
    method: string,
    path: string,
    params: Record<string, any> | null,
    body: any | null,
    headers: Headers | null = null
): Promise<T> {
    return await wrappedFetch(method, path, headers ?? new Headers(), params, body)
}

export async function adminGet<T>(path: string, arg: Record<string, any> = {}): Promise<T> {
    return await adminFetch('get', path, arg, null)
}

export async function adminPut<T>(path: string, body: any): Promise<T> {
    return await adminFetch('put', path, null, body)
}

export async function adminPost<T>(path: string, body: any): Promise<T> {
    return await adminFetch('post', path, null, body)
}

export async function adminDownload<T>(path: string, name: string): Promise<any> {
    const response = await wrappedFetchResponse('post', path, adminHeader(), null, null)
    if (response.ok) {
        const blob = await response.blob()
        const file = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    } else {
        await wrapError(response)
    }
}

export async function adminDelete<T>(path: string): Promise<T> {
    return await adminFetch('delete', path, null, null)
}

export async function postAnon<T>(path: string, body: any | null, headers: Headers | null = null): Promise<T> {
    return await anonFetch('post', path, null, body, headers)
}

export async function getAnon<T>(path: string, arg: Record<string, any> = {}): Promise<T> {
    return await anonFetch('get', path, arg, null)
}

export async function anonDelete<T>(path: string): Promise<T> {
    return await anonFetch('delete', path, null, null)
}