<script lang="ts">
import {Car, Result, Sort} from '../../model/model';
import CarService from '../../services/CarService';
import {formatDate} from '../../util/format';
import {Message, MessageType} from '../global/MessageView.vue';
import EntityTable from './EntityTable.vue';
import SortableHeader from "../util/SortableHeader.vue";

const carService = new CarService()

export default {
    props: {
      cars: {
          type: [Object as () => Result<String, Array<Car>> | Array<Car>]
      },
      sortable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
        return {
            reportGeneratingIds: new Set() as Set<String>,
            sort: null as Sort | null
        };
    },
    methods: {
      async generateReport(car: Car) {
          const id = car.id;
          if (!this.reportGeneratingIds.has(id)) {
              this.reportGeneratingIds.add(id);
              try {
                  await carService.generateReport(car);
              }
              catch (e) {
                  this.emitter.emit("log", new Message("car.reportFail", MessageType.Error));
              }
              finally {
                  this.reportGeneratingIds.delete(id);
              }
          }
      },
      formatDate(date: String | null): String {
        return formatDate(date)
      },
      triggerSort(field: String) {
        if (this?.sort?.field === field) {
          this.sort = this.sort.reverse()
        } else {
          this.sort = new Sort(field)
        }
        this.$emit('trigger-sort', this.sort)
      }
    },
    emits: ['car-select', 'trigger-sort'],
    components: {SortableHeader, EntityTable }
}
</script>

<template>
  <EntityTable :result="cars" :cols="6"
    loading-message="car.load.inProgress" empty-message="car.load.empty" error-message="car.load.fail" limited-message="car.load.limited"
    @entity-select="(c: Car) => $emit('car-select', c)">
    <template #headers>
      <SortableHeader :sortable="sortable" field="plate" header="car.details.plate" :sort="sort" @trigger-sort="triggerSort" />
      <SortableHeader :sortable="sortable" field="brand" header="car.details.brand" :sort="sort" @trigger-sort="triggerSort" />
      <SortableHeader :sortable="sortable" field="model" header="car.details.model" :sort="sort" @trigger-sort="triggerSort" />
      <SortableHeader :sortable="sortable" field="next_service" header="car.details.nextService" :sort="sort" @trigger-sort="triggerSort" />
      <SortableHeader :sortable="sortable" field="license_expiry" header="car.details.licenseExp" :sort="sort" @trigger-sort="triggerSort" />
      <th>{{ $t("car.details.worksheet") }}</th>
    </template>
    <template #entity="car: Car">
      <td>{{ car.plate }}</td>
      <td>{{ car.brand }}</td>
      <td>{{ car.model }}</td>
      <td class="center">{{ formatDate(car.nextService) }}</td>
      <td class="center">{{ formatDate(car.licenseExpiry) }}</td>
      <td class="center" @click.stop="generateReport(car)">{{ $t("car.generateReport") }} <font-awesome-icon icon="file-export" /></td>
    </template>
  </EntityTable>
</template>

<style scoped>
.short {
  width: 20%;
}
</style>