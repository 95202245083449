<script lang="ts">
import {AdminLogin, OtpLogin} from '../model/model';
import router from '../router';
import LoginService from '../services/LoginService';
import {credentialStore} from '../stores/credentials';

import AdminLoginForm, {AdminCredentials} from '../components/login/AdminLoginForm.vue';
import UserLoginForm from '../components/login/UserLoginForm.vue';
import {Message, MessageType} from '../components/global/MessageView.vue';

export const loginService = new LoginService();

export default {
    props: {
      magicLink: String,
      userId: String,
      emailConsent: String
    },
    data: () => ({
        forAdmin: false
    }),
    methods: {
      reportInvalid() {
        this.emitter.emit("log", new Message("login.invalid", MessageType.Error))
      },
        async adminLogin(credentials: AdminCredentials) {
            try {
                const token = await loginService.adminLogin(credentials.username, credentials.password);
                credentialStore().setAdminJwt(token.name, token.jwt);
                router.push({ path: "/users" });
            }
            catch (e) {
              this.reportInvalid()
            }
        },
        async userLogin(otp: OtpLogin) {
            try {
                const token = await loginService.userLogin(otp);
                credentialStore().setUserJwt(token.name, token.jwt);
                router.push({ path: "/profile" });
            }
            catch (e) {
              this.reportInvalid()
            }
        }
    },
    async created() {
      if (this.magicLink) {
        try {
          const token = await loginService.linkLogin(this.magicLink);
          credentialStore().setUserJwt(token.name, token.jwt);
          router.push({ path: "/" });
        }
        catch (e) {
          this.emitter.emit("log", new Message("login.invalid", MessageType.Error))
        } 
      } else if (this.userId && this.emailConsent) {
        try {
          console.log(`Unsubscribing ${this.userId}, ${this.emailConsent}`)
          await loginService.emailUnsubscribe(this.userId, this.emailConsent)
          this.emitter.emit("log", new Message("email.unsubscribe.ok", MessageType.Success))
          router.push({ path: "/" })
        } catch (e) {
          console.error(e)
          this.emitter.emit("log", new Message("email.unsubscribe.fail", MessageType.Error))
        }
      }
    },
    components: { AdminLogin, AdminLoginForm, UserLoginForm }
}
</script>

<template>
  <main>
    <div v-if="!magicLink">
      <table>
        <tr>
          <td class="selector" :class="forAdmin ? '' : 'selected'" @click="forAdmin = false">{{ $t("login.userLogin") }}</td>
          <td class="selector" :class="forAdmin ? 'selected' : ''" @click="forAdmin = true">{{ $t("login.adminLogin") }}</td>
        </tr>
      </table>
      <UserLoginForm v-if="!forAdmin" @trigger-login="(otp: OtpLogin) => userLogin(otp)" />
      <AdminLoginForm v-if="forAdmin" @trigger-login="(c: AdminCredentials) => adminLogin(c)" />
    </div>
  </main>
</template>

<style scoped>
main {
  position: fixed !important;
  top: 0;
  left: initial !important;
  width: 100%;
  height: 100%;
  display: flex;
}

main>div {
  margin: auto;
  width: 300px;
  height: 220px;
  padding-bottom: 1rem;
}

main table {
  width: 100%;
  table-layout: fixed;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  border: none;
  border-spacing: 0;
  border-bottom: 5px solid var(--global-color-selected);
}

main table tr {
  background-color: var(--global-color-background);
  color: white;
}

.selector {
  width: 50%;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}

.selector.selected {
  font-weight: bolder;
  background-color: var(--global-color-selected);
}
</style>
