"use strict";

import { uuid } from "vue-uuid";

export class DialogMessage {
    constructor(
        public readonly message: string,
        public readonly props: Record<string, unknown>,
        public readonly cancelButton: string,
        public readonly confirmButton: string
    ) {}
}

export class Dialog {
    constructor(public readonly id: String, public readonly data: DialogMessage) {}
}

class DialogHandler {
    constructor(public readonly id: String, public onConfirm: (() => void), public onCancel: (() => void)) {}
}

export default class ConfirmService {

    private currentDialog: DialogHandler | null = null

    constructor(readonly emitter: any) {
        emitter.on('confirm-cancel', (id: String) => this.onResponse(id, false))
        emitter.on('confirm-ok', (id: String) => this.onResponse(id, true))
    }

    private onResponse(id: String, confirmed: Boolean) {
        const dialog = this.currentDialog
        this.currentDialog = null
        if (dialog && dialog.id === id) {
            if (confirmed) {
                dialog.onConfirm()
            } else {
                dialog.onCancel()
            }
        }
    }

    public askToConfirm(message: DialogMessage, onConfirm: (() => void), onCancel: (() => void) = () => {}) {
        const id = uuid.v4()
        this.currentDialog = new DialogHandler(id, onConfirm, onCancel)
        this.emitter.emit('confirm-request', new Dialog(id, message))
    }

}