"use strict";

import { dtoToSuggestedWork } from "../model/dto";
import { SuggestedWork } from "../model/model";
import { adminDelete, adminPost, adminPut } from "./http";

export default class SuggestedWorkService {

    public async updateSuggestion(sugestion: SuggestedWork): Promise<SuggestedWork> {
        const updated = await adminPut(`cars/${sugestion.car}/suggestedWorks/${sugestion.id}`, sugestion)
        return dtoToSuggestedWork(updated)
    }

    public async createSuggestion(sugestion: SuggestedWork): Promise<SuggestedWork> {
        const updated = await adminPost(`cars/${sugestion.car}/suggestedWorks/`, sugestion)
        return dtoToSuggestedWork(updated)
    }

    public async updateOrCreateSuggestion(suggestion: SuggestedWork): Promise<SuggestedWork> {
        if (suggestion.id === "") {
            return this.createSuggestion(suggestion)
        } else {
            return this.updateSuggestion(suggestion)
        }
    }

    public async deleteSuggestionById(id: String): Promise<any> {
        return await adminDelete(`suggestedWorks/${id}`)
    }

    public async deleteSuggestion(suggestion: SuggestedWork): Promise<any> {
        return await this.deleteSuggestionById(suggestion.id)
    }

}