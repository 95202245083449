<script setup lang="ts">

import EditableInput from "../util/EditableInput.vue";
import {MutableCar} from "../../model/model";
import {commonColors, commonFuel, commonTypes} from "../../util/commonOptions";
import {ref} from "vue";
import ExpandSeparator from "../util/ExpandSeparator.vue";

defineProps<{
  carData: MutableCar,
  editable: Boolean
}>()

const expanded = ref(false)

defineEmits(['brand-changed'])

</script>

<template>
  <table id="car-details">
    <colgroup>
      <col class="header-col"/>
      <col class="value-col"/>
    </colgroup>
    <tbody>
      <tr data-field="plate">
        <th>{{ $t("car.details.plate") }}:</th>
        <td><EditableInput v-model="carData.plate" :editable="!!editable" type="capital" /></td>
      </tr>
      <tr data-field="licenseExpiry">
        <th>{{ $t("car.details.licenseExp") }}:</th>
        <td><EditableInput v-model="carData.licenseExpiry" :editable="!!editable" type="date" /></td>
      </tr>
      <tr data-field="nextService">
        <th>{{ $t("car.details.nextService") }}:</th>
        <td><EditableInput v-model="carData.nextService" :editable="!!editable" type="date" /></td>
      </tr>
    </tbody>
    <tbody v-if="expanded">
      <tr data-field="brand">
        <th>{{ $t("car.details.brand") }}:</th>
        <td><EditableInput v-model="carData.brand" :editable="!!editable" type="string" raw-datalist="brand-datalist" @update:modelValue="$emit('brand-changed')" /></td>
      </tr>
      <tr data-field="model">
        <th>{{ $t("car.details.model") }}:</th>
        <td><EditableInput v-model="carData.model" :editable="!!editable" type="string" raw-datalist="model-datalist" /></td>
      </tr>
      <tr data-field="year">
        <th>{{ $t("car.details.year") }}:</th>
        <td><EditableInput v-model="carData.year" :editable="!!editable" type="year" /></td>
      </tr>
      <tr data-field="fuel">
        <th>{{ $t("car.details.fuel") }}:</th>
        <td><EditableInput v-model="carData.fuel" :editable="!!editable" type="localOpt" :options="commonFuel" /></td>
      </tr>
      <tr data-field="displacementQcm">
        <th>{{ $t("car.details.displacementQcm") }}:</th>
        <td><EditableInput v-model="carData.displacementQcm" :editable="!!editable" type="int" /></td>
      </tr>
      <tr data-field="performanceKw">
        <th>{{ $t("car.details.performanceKw") }}:</th>
        <td><EditableInput v-model="carData.performanceKw" :editable="!!editable" type="kw" /></td>
      </tr>
      <tr data-field="type">
        <th>{{ $t("car.details.type") }}:</th>
        <td><EditableInput v-model="carData.type" :editable="!!editable" type="localOpt" :options="commonTypes" /></td>
      </tr>
      <tr data-field="color">
        <th>{{ $t("car.details.color") }}:</th>
        <td><EditableInput v-model="carData.color" :editable="!!editable" type="localOpt" :options="commonColors" /></td>
      </tr>
      <tr data-field="vin">
        <th>{{ $t("car.details.vin") }}:</th>
        <td><EditableInput v-model="carData.vin" :editable="!!editable" type="string" /></td>
      </tr>
      <tr data-field="motorNumber">
        <th>{{ $t("car.details.motorNum") }}:</th>
        <td><EditableInput v-model="carData.motorNumber" :editable="!!editable" type="string" /></td>
      </tr>
    </tbody>
  </table>
  <ExpandSeparator v-model="expanded" />
</template>

<style scoped>
table {
  display: inline-block;
  vertical-align: top;
}

th, td {
  font-size: large;
}

table#car-details th {
  text-align: right;
  font-weight: bold;
  padding-left: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

#expand-click {
  cursor: pointer;
}
</style>