<script lang="ts">
import router from '../router';
import UserService from '../services/UsersService';

import {Message, MessageType} from '../components/global/MessageView.vue';
import UserDetails from '../components/UserDetails.vue';
import {MutableUser, UserWithCars} from '../model/model';

export const userService = new UserService();

export default {
    props: {
      userId: {
        type: [String, null],
        default: null
      }
    },
    data() {
      return {
        loadedUser: null,
        headerLabel: this.userId ? this.$t("user.loading") : this.$t("user.new"),
        empty: MutableUser.new
      }
    },
    methods: {
      loadUser: async function () {
        if (this.userId) {
          try {
            const user = await userService.fetchUserWithCars(this.userId)
            this.setUser(user)
          } catch (e) {
            this.emitter.emit("log", new Message("user.loadFail", MessageType.Error))
          }  
        } else {
          this.setUser(null)
        }
      },
      setUser(user: UserWithCars | null) {
        this.loadedUser = user ?? new UserWithCars(MutableUser.new(), [])
        if (user !== null) {
          this.headerLabel = this.loadedUser.user.name
          this.setTitle(this.loadedUser.user.name)
        }
      },
      setTitle(title: String) {
        document.title = `Mason & Steinberg | ${title}`
      },
      goToUsers() {
        router.push({name: 'users'})
      },
      nameChanged(name: String) {
        this.headerLabel = name
        this.setTitle(name)
      }
    },
    mounted() {
      this.setTitle(this.userId ? this.$t("user.loading") : this.$t("user.new"))
      this.loadUser()
    },
    components: { UserDetails }
}
</script>

<template>
  <main>
    <div id="title-container">
      <h1 id="title-label" @click="goToUsers()">{{ $t("global.users") }}</h1>
      <h1>></h1>
      <h1>{{ headerLabel }}</h1>
    </div>
    <UserDetails  v-if="loadedUser" :loaded-user="loadedUser" @name-change="nameChanged" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

#title-label {
  font-weight: bold;
  cursor: pointer;
}

h1#search-label {
  font-style: italic;
  margin-right: 1rem;
}

input {
  font-size: x-large;
  padding: 0.5rem;
  width: 500px;
  display: inline-block;
}
</style>
