<script setup lang="ts">

const props = defineProps<{
  modelValue: Boolean
}>()

defineEmits(["update:modelValue"])
</script>

<template>
  <h2 class="expand-message" :title="$t(props.modelValue ? 'util.showLess' : 'util.showMore')"
      @click="$emit('update:modelValue', !props.modelValue)"><span>{{ modelValue ? '▲' : '▼' }}</span></h2>
</template>

<style scoped>
.expand-message {
  width: 100%;
  text-align: center;
  border-bottom: 3px solid var(--global-color-selected);
  line-height: 0.1em;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.expand-message>span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: var(--color-background);
}
</style>