<script lang="ts">

import LangSelector from './LangSelector.vue';

export class AdminCredentials {
  constructor(public username: String, public password: String) {}
}

export default {
    data: () => ({
        data: new AdminCredentials("", "")
    }),
    methods: {
        login() {
            this.$emit("trigger-login", this.data);
        }
    },
    components: { LangSelector }
}
</script>

<template>
  <div id="admin-login">
    <label for="username-input">{{ $t("login.username") }}:</label>
    <input id="username-input" v-model="data.username" type="text" :placeholder="$t('login.username')"
           v-on:keyup.enter="login" autofocus>
    <label for="password-input">{{ $t("login.password") }}:</label>
    <input id="password-input" v-model="data.password" type="password" :placeholder="$t('login.password')"
           v-on:keyup.enter="login">
    <div>
      <button @click="login">{{ $t("login.login") }}</button>
      <LangSelector />
    </div>
  </div>
</template>

<style scoped>
#admin-login {
  padding-left: 1rem;
  padding-right: 1rem;
}

#admin-login>div {
  margin-top: 0.5rem;
}

input {
  display: block;
  width: 100%;
  padding: 0.5rem;
}

button {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  color: var(--global-color-background);
  cursor: pointer;
}
</style>
