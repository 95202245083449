<script setup lang="ts">
import {computed} from 'vue';
import {windowWidth} from "../../main";

const isCompact = computed(() => windowWidth.value <= 480);
</script>

<template>
  <slot v-if="isCompact" name="compact"></slot>
  <slot v-if="!isCompact" name="regular"></slot>
</template>