<script lang="ts">
import router from '../../router';
import ConfirmService, { DialogMessage } from '../../services/ConfirmService';
import { credentialStore } from '../../stores/credentials';

class NavOption {
  constructor(public label: string, public icon: String, public path: String) {}
}

export const adminTopOptions: Array<NavOption> = [
  new NavOption("global.upcoming", "calendar-days", "/upcoming"),
  new NavOption("global.users", "users", "/users"),
  new NavOption("global.cars", "car", "/cars"),
  new NavOption("settings.settings", "sliders", "/settings"),
]
export const userTopOptions: Array<NavOption> = [
  new NavOption("global.profile", "user", "/profile")
]
export const bottomOption = new NavOption("global.logout", "sign-out", "/logout")

export default {
    props: {
        options: Array<Object>
    },
    data() {
        return {
            topOptions: credentialStore().isAdmin ? adminTopOptions : userTopOptions,
            bottomOption: bottomOption,
            confirmDialog: null,
            confirmService: new ConfirmService(this.emitter),
            minimised: true
        };
    },
    computed: {
        loggedIn() {
            return credentialStore().loggedIn;
        },
        isAdmin() {
            return credentialStore().isAdmin;
        },
        name() {
          return credentialStore().name
        }
    },
    methods: {
        logout() {
            this.confirmDialog = null
            credentialStore().logout();
            router.push({ path: "/login" });
        },
        confirmLogout() {
            this.confirmService.askToConfirm(new DialogMessage("modal.message.logout", {}, "modal.cancel", "global.logout"), this.logout);
        },
        toggle() {
          this.minimised = !this.minimised
        }
    }
}
</script>

<template>
  <nav id="navbar" :class="minimised ? 'min' : 'full'" v-if="loggedIn">
    <ul id="nav-list">
      <div id="nav-hamburger" @click="toggle"><font-awesome-icon class="nav-icon" icon="bars" /></div>
      <router-link to="/" class="top-label">
        <li id="home-label">Mason & Steinberg</li>
      </router-link>
      <li id="name-label" class="top-label" v-if="isAdmin">{{ name }}</li>
      <router-link :to="o.path.toString()" v-for="o in topOptions" :key="o.toString()" @click="toggle">
        <li class="nav-link">
          <font-awesome-icon class="nav-icon" :icon="o.icon" />
          {{ $t(o.label) }}
        </li>
      </router-link>
      <div class="bottom-link" @click="toggle">
        <li class="nav-link" @click="confirmLogout">
          <font-awesome-icon class="nav-icon" :icon="bottomOption.icon" />
          {{ $t(bottomOption.label) }}
        </li>
      </div>
    </ul>
  </nav>
</template>

<style scoped>
#navbar {
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  font-size: 12px;
  margin-left: 0;
  background: var(--global-color-background);
  box-shadow: 0px 0px 8px rgba(0,0,0,.6);
}

#nav-hamburger {
  display: none;
}

#home-label, #nav-hamburger {
  cursor: pointer;
}

#name-label {
  font-weight: normal;
}

.top-label:last-of-type {
  border-bottom: 0.5rem solid white;
  padding-bottom: 0.8rem;
}

.nav-link, #name-label, #home-label, #nav-hamburger {
  font-size: x-large;
  font-weight: bold;
  list-style-type: none;
  padding-top: 0.4em;
  padding-bottom: 0.3em;
  padding-left: 1em;
  color: white;
}

.nav-link {
  cursor: pointer;
}

.nav-link:hover, .router-link-active>.nav-link {
  background: var(--global-color-selected);
}

#nav-list {
  height: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0;
}

.bottom-link {
  position: absolute;
  bottom: 0;
  padding-bottom: 0.7em;
  width: 100%;
}

.nav-icon {
  font-size: smaller;
  margin-right: 0.5em;
}

@media (max-width:480px)  {
  .bottom-link {
    position: relative;
    bottom: auto;
    padding-bottom: 0;
  }

  #navbar {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 0.5rem solid white;
  }

  #home-label {
    display: inline-block;
    padding-left: 0;
  }

  #nav-hamburger {
    display: inline-block;
  }

  #navbar.min>ul>li, #navbar.min>ul>a:not(.top-label), #navbar.min>ul>.bottom-link {
    display: none;
  }
}
</style>
