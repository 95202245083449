<script lang="ts">
import {Car, EditState, InvalidField, MutableUser, Ok, Result, UserWithCars, ValidationError} from '../model/model';


import UserService from '../services/UsersService';
import EditableInput from './util/EditableInput.vue';
import {Message, MessageType} from './global/MessageView.vue';

import router from '../router';
import ConfirmService, {DialogMessage} from '../services/ConfirmService';
import CarsTable from './table/CarsTable.vue';

import CarCard from './card/CarCard.vue';
import CarsListing from "./reactive/CarsListing.vue";
import EmailConsentView from "./user/EmailConsentView.vue";

const userService = new UserService()

export default {
    props: {
        loadedUser: {
          type: Object as () => UserWithCars
        }
    },
    data() {
      return {
        newUser: this.loadedUser.user.id === "",
        savedUser: this.loadedUser.user,
        userData: MutableUser.of(this.loadedUser.user),
        state: this.loadedUser.user.id === "" ? EditState.Edit : EditState.View,
        loadedCars: this.loadedUser.cars,
        confirmService: new ConfirmService(this.emitter)
      }
    },
    methods: {
      startEdit() {
        this.state = EditState.Edit
      },
      async saveEdit() {
        this.state = EditState.Saving
        this.clearSaveError()
        try {
          const updated = await userService.updateOrCreateUser(this.userData)
          const nameChange = updated.name !== this.savedUser.name
          this.savedUser = updated
          this.userData = MutableUser.of(updated)
          this.state = EditState.View
          if (nameChange)  {
            this.$emit('name-change', updated.name)
          }
          const message = this.newUser ? "message.user.created.ok" : "message.user.updated.ok"
          this.emitter.emit("log", new Message(message, MessageType.Success))
          this.newUser = false;
        } catch (e) {
          if (e instanceof ValidationError) {
            this.setSaveError(e as ValidationError)
          } else {
            this.clearSaveError()
          }

          this.state = EditState.Edit
          const message = this.newUser ? "message.user.created.fail" : "message.user.updated.fail"
          this.emitter.emit("log", new Message(message, MessageType.Error))
        }
      },
      revert() {
        this.clearSaveError()
        if (this.newUser) {
          router.back()
        } else {
          this.state = EditState.View
          this.userData = MutableUser.of(this.savedUser)
        }
      },
      triggerDelConfirm() {
        this.state = EditState.View
        this.confirmService.askToConfirm(
          new DialogMessage("message.user.deleted.confirm", {name: this.savedUser.name}, "modal.cancel", "modal.delete"),
          () => this.userDelConfirm(),
          () => this.state = EditState.View
        )
      },
      cancelDel() {
        this.state = EditState.View
      },
      async userDelConfirm() {
        this.state = EditState.Saving
        try {
          await userService.deleteUser(this.savedUser)
          router.back()
          this.emitter.emit("log", new Message("message.user.deleted.ok", MessageType.Success))
        } catch (e) {
          this.state = EditState.View
          this.emitter.emit("log", new Message("message.user.deleted.fail", MessageType.Error))
        }
      },
      createCar() {
        router.push({ name: "createCar", params: {userId: this.savedUser.id} });
      },
      carSelect: function(car: Car) {
        router.push({ name: "viewCar", params: {carId: car.id} });
      },
      clearSaveError() {
        document.querySelectorAll(`#user-details .validation-message`).forEach(span => span.textContent = "")
      },
      setSaveError(error: ValidationError) {
        error.errors.forEach((error: InvalidField) => {
          const span = document.querySelector(`#user-details tr[data-field="${error.property}"] .validation-message`)
          if (span != null) {
            span.textContent = error.localised.toString()
          }
        })
      }
    },
    computed: {
      editShown() { return this.state === EditState.View },
      editable() { return this.state === EditState.Edit },
      editMode() { return this.state === EditState.Edit || this.state === EditState.Saving },
      savePending() { return this.state === EditState.Saving },
      carsAsResult(): Result<String, Array<Car>> {
        return new Ok(this.loadedUser.cars)
      }
    },
    components: {EmailConsentView, CarsListing, EditableInput, CarsTable, CarCard }
}
</script>

<template>
  <div>
    <div id="actions">
      <button id="edit-button" class="action-button" v-if="editShown" @click="startEdit" :title="$t('user.action.edit')"><font-awesome-icon class="nav-icon" icon="pen-to-square" /></button>
      <button id="delete-button" class="action-button" v-if="editShown" @click="triggerDelConfirm" :title="$t('user.action.delete')"><font-awesome-icon class="nav-icon" icon="trash" /></button>
      <button id="save-button" class="action-button" v-if="editMode" :disabled="savePending" @click="saveEdit" :title="$t('user.action.save')"><font-awesome-icon class="nav-icon" icon="floppy-disk" /></button>
      <button id="cancel-button" class="action-button" v-if="editMode" :disabled="savePending" @click="revert" :title="$t('user.action.undo')"><font-awesome-icon class="nav-icon" icon="clock-rotate-left" /></button>
    </div>
    <table id="user-details">
      <colgroup>
        <col class="header-col"/>
        <col class="value-col"/>
      </colgroup>
      <tr data-field="name">
        <th>{{ $t("user.details.name") }}:</th>
        <td><EditableInput v-model="this.userData.name" :editable="editable" type="string" /></td>
      </tr>
      <tr data-field="phone">
        <th>{{ $t("user.details.phone") }}:</th>
        <td><EditableInput v-model="this.userData.phone" :editable="editable" type="tel" /></td>
      </tr>
      <tr data-field="email">
        <th>{{ $t("user.details.email") }}:</th>
        <td><EditableInput v-model="this.userData.email" :editable="editable" type="email" /></td>
      </tr>
    </table>
    <div id="cars-data" v-if="savedUser.id !== ''">
      <EmailConsentView :given-consent="this.userData.hasEmailConsent"
                        :user-id="savedUser.id"
                        :can-enable="this.userData.email !== null"
                        @consent-changed="(c: boolean) => this.userData.hasEmailConsent = c" />
      <h1>{{ $t("user.cars") }}</h1>
      <CarsListing :cars="this.carsAsResult" @car-select="carSelect" :sortable="false" />
      <button id="new-car-button" class="action-button" @click="createCar" :title="$t('user.action.newCar')"><font-awesome-icon class="nav-icon" icon="plus-circle" /></button>
    </div>
  </div>
</template>

<style>
table#user-details input {
  width: 300px
}
</style>

<style scoped>
table#user-details, #actions {
  display: inline-block;
  vertical-align: top;
}

#actions {
  padding-top: 0.5rem;
}

#new-car-button {
  margin-left: 0;
  margin-top: 0.4rem;
}

#actions>button:not([disabled]):hover, #new-car-button:not([disabled]):hover {
  background: var(--global-color-selected);
}

table#user-details th, table#user-details td {
  font-size: large;
}

table#user-details th {
  text-align: right;
  font-weight: bold;
  padding-left: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

table#user-details td {
  width: 500px;
}

#cars-data {
  padding-top: 0.5rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
</style>