import { defineStore } from 'pinia'

class JwtInfo {
  constructor(public readonly name: String, public readonly jwt: String, public readonly admin: boolean) {}
}

export const credentialStore = defineStore('credential', {
  state: () => ({
    jwt: null
  }),
  getters: {
    name() {
      return this.jwt?.name
    },
    loggedIn(): Boolean {
      return this.jwt != null
    },
    isAdmin(): Boolean {
      return this.jwt?.admin ?? false
    },
    anyJwt() {
      return this.jwt?.jwt
    },
    adminJwt() {
      if (this.isAdmin) {
        return this.jwt?.jwt
      } else {
        return null
      }
    },
    isUser(): Boolean {
      return this.loggedIn && !this.isAdmin
    }
  },
  actions: {
    setAdminJwt(name: String, jwt: String) {
      this.jwt = new JwtInfo(name, jwt, true)
    },
    setUserJwt(name: String, jwt: String) {
      this.jwt = new JwtInfo(name, jwt, false)
    },
    logout() {
      this.jwt = null
    }
  },
  persist: true
})