export class AdminLogin {
    constructor(public readonly username: String, public readonly password: String) {}
}

export class OtpLogin {
    constructor(public readonly otpId: String, public readonly otp: String) {}
}

export class JwtToken {
    constructor(public readonly name: String, public readonly jwt: String) {}
}

export class InvalidField {
    constructor(public readonly property: String, public readonly code: String, public readonly localised: String) {}
}

export class ValidationError {
    constructor(public readonly errors: Array<InvalidField>) {}
}

export class AuthError {
    constructor(public readonly expired: Boolean) {}
}

export class ResultComm {
    public isOk() {
        return this instanceof Ok
    }
    public isFail() {
        return this instanceof Fail
    }
    public isLoading() {
        return this instanceof Loading
    }
}

export class Loading<Q> extends ResultComm {
    constructor(public readonly query: Q | null) {
        super()
    }
}
export class Fail extends ResultComm {
    constructor(public readonly message: String) {
        super()
    }
}
export class Ok<T> extends ResultComm {
    constructor(public readonly data: T, public readonly limited: Boolean = false) {
        super()
    }
}

export type Result<Q, T> = Loading<Q> | Fail | Ok<T>

export class LimitedResult<T> {
    constructor(public readonly items: Array<T>, public readonly limited: Boolean) {}
}

export class UserWithCars {
    constructor(public readonly user: User, public readonly cars: Array<Car>) {}
}

export class CarWithWorks {
    constructor(public readonly car: Car, public readonly works: Array<Work>, public readonly suggestions: Array<SuggestedWork>) {}
}

export interface User {
    readonly id: string
    readonly name: string
    readonly phone: string
    readonly email: string
    readonly hasEmailConsent: boolean
}

export class UserData implements User {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly phone: string,
        public readonly email: string,
        public readonly hasEmailConsent: boolean
    ) {}
}

export class MutableUser implements User {
    constructor(
        public id: string,
        public name: string,
        public phone: string,
        public email: string,
        public hasEmailConsent: boolean
    ) {}

    static of(user: User): MutableUser {
        return new MutableUser(user.id, user.name, user.phone, user.email, user.hasEmailConsent)
    }

    static new(): MutableUser {
        return new MutableUser("", "", "", "", false)
    }
}

export interface Car {
    readonly id: string
    readonly owner: string
    readonly brand: string
    readonly plate: string
    readonly model: string
    readonly vin: string | null
    readonly motorNumber: string | null
    readonly licenseExpiry: string | null
    readonly nextService: string | null
    readonly color: string | null
    readonly year: number | null
    readonly displacementQcm: number | null
    readonly performanceKw: number | null
    readonly fuel: string | null
    readonly type: string | null
}

export class CarData implements Car {
    constructor(
        public readonly id: string,
        public readonly owner: string,
        public readonly brand: string,
        public readonly plate: string,
        public readonly model: string,
        public readonly vin: string | null,
        public readonly motorNumber: string | null,
        public readonly licenseExpiry: string | null,
        public readonly nextService: string | null,
        public readonly color: string | null,
        public readonly year: number | null,
        public readonly displacementQcm: number | null,
        public readonly performanceKw: number | null,
        public readonly fuel: string | null,
        public readonly type: string | null
    ) {}
}

export class MutableCar implements Car {
    constructor(
        public id: string,
        public owner: string,
        public brand: string,
        public plate: string,
        public model: string,
        public vin: string | null,
        public motorNumber: string | null,
        public licenseExpiry: string | null,
        public nextService: string | null,
        public color: string | null,
        public year: number | null,
        public displacementQcm: number | null,
        public performanceKw: number | null,
        public fuel: string | null,
        public type: string | null
    ) {}

    static of(car: Car): MutableCar {
        return new MutableCar(
            car.id, car.owner, car.brand, car.plate, car.model, car.vin,
            car.motorNumber, car.licenseExpiry, car.nextService, car.color, car.year,
            car.displacementQcm, car.performanceKw, car.fuel, car.type
        )
    }

    static new(): MutableCar {
        return this.newFor(null)
    }

    static newFor(userId: string): MutableCar {
        return new MutableCar("", userId, "", "", "", null, null, null, null, null, null, null, null, null, null)
    }
}

export interface Work {
    readonly id: String,
    readonly car: String,
    readonly date: String,
    readonly name: String,
    readonly kilometers: Number,
    readonly components: Array<String>,
    readonly comment: String | null
}

export class WorkData implements Work {
    constructor(
        public readonly id: String,
        public readonly car: String,
        public readonly date: String,
        public readonly name: String,
        public readonly kilometers: Number,
        public readonly components: Array<String>,
        public readonly comment: String | null
    ) {}
}

export class MutableWork implements Work {
    constructor(
        public id: String,
        public car: String,
        public date: String,
        public name: String,
        public kilometers: Number,
        public components: Array<String>,
        public comment: String | null
    ) {}

    static of(work: Work): MutableWork {
        return new MutableWork(work.id, work.car, work.date, work.name, work.kilometers, work.components, work.comment)
    }

    static new(): MutableWork {
        return this.newFor(null)
    }

    static newFor(car: string): MutableWork {
        return new MutableWork("", car, "", "", 0, [], null)
    }
}

export interface SuggestedWork {
    readonly id: String,
    readonly car: String,
    readonly date: String | null,
    readonly name: String,
    readonly components: Array<String>
}

export class SuggestedWorkData implements SuggestedWork {
    constructor(
        public readonly id: String,
        public readonly car: String,
        public readonly date: String | null,
        public readonly name: String,
        public readonly components: Array<String>
    ) {}
}

export class MutableSuggestedWork implements SuggestedWork {
    constructor(
        public id: String,
        public car: String,
        public date: String | null,
        public name: String,
        public components: Array<String>
    ) {}

    static of(suggested: SuggestedWork): MutableSuggestedWork {
        return new MutableSuggestedWork(suggested.id, suggested.car, suggested.date, suggested.name, suggested.components)
    }

    static new(): MutableSuggestedWork {
        return this.newFor(null)
    }

    static newFor(car: string): MutableSuggestedWork {
        return new MutableSuggestedWork("", car, null, "", [])
    }
}

export enum InputType {
    tel = "tel", email = "email", string = "string", integer = "int", password = "pass"
}

export enum EditState {
    View, Edit, Saving
}

export class ExpandingView<T> {
    constructor(public data: T, public expanded: Boolean) {}

    public toggle() {
        this.expanded = !this.expanded
    }
}

export class Setting {
    constructor(public name: String, public jsonData: String) {}
}

export enum SortDir {
    ASC = "asc", DESC = "desc"
}

export class Sort {
    constructor(public field: String, public direction: SortDir = SortDir.ASC) {}

    reverse(): Sort {
        return new Sort(this.field, (this.direction == SortDir.ASC) ? SortDir.DESC : SortDir.ASC)
    }
}