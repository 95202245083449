<script lang="ts">
import { setLang, activeLang } from '../../main';

export default {
  methods: {
    selectLang(lang: "hu" | "en") {
      setLang(lang)
    }
  }
}
</script>

<template>
  <div class="lang-select">
    <button @click="this.selectLang('en')">🇬🇧</button>
    <button @click="this.selectLang('hu')">🇭🇺</button>
  </div>
</template>

<style scoped>
.lang-select {
  vertical-align: middle;
  float: right;
}
.lang-select>button {
  background: none;
  border: none;
  font-size: x-large;
  padding: 0.3rem;
  cursor: pointer;
}
</style>
