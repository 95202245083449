import './assets/main.css';

import {createPinia} from 'pinia';
import {createApp, ref} from 'vue';

import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faBars,
    faCalendar,
    faCalendarCheck,
    faCalendarDays,
    faCar,
    faClockRotateLeft,
    faEnvelope,
    faFileExport,
    faFloppyDisk,
    faGauge,
    faMinusCircle,
    faPenToSquare,
    faPhone,
    faPlusCircle,
    faSignOut,
    faSliders,
    faSort,
    faSortDown,
    faSortUp,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faWrench
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import mitt from 'mitt';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {Message, MessageType} from './components/global/MessageView.vue';

import NavBar from './components/global/NavBar.vue';

import {createI18n} from 'vue-i18n';
import App from './App.vue';
import {messages} from './messages';
import router from './router';
import SuggestionService from './services/SuggestionService';
import {commonColors, commonFuel, CommonOptions, commonTypes} from './util/commonOptions';
import {localeStore} from './stores/locale';
import {AuthError} from './model/model';
import {credentialStore} from './stores/credentials';
import {VueReCaptcha} from "vue-recaptcha-v3";

library.add(faUsers);
library.add(faCar);
library.add(faSliders);
library.add(faSignOut);
library.add(faPenToSquare);
library.add(faFloppyDisk);
library.add(faClockRotateLeft);
library.add(faTrash);
library.add(faUserPlus);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faFileExport);
library.add(faUser);
library.add(faBars);
library.add(faPhone);
library.add(faEnvelope);
library.add(faWrench);
library.add(faCalendarDays);
library.add(faGauge);
library.add(faCalendar);
library.add(faCalendarDays);
library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faCalendarCheck);

export const emitter = mitt();

export const baseUrl = import.meta.env.VITE_BASE_URL

const app = createApp(App)
app.config.globalProperties.emitter = emitter;

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(VueReCaptcha, { siteKey: '6Lc8twwpAAAAAE2tvbqWcRaUjmY196r9z7E4yCFD', loaderOptions: {autoHideBadge: true}})

app.use(pinia)
app.use(router)

const i18n = createI18n({
    legacy: false,
    availableLocales: ["hu", "en"],
    locale: localeStore().locale,
    fallbackLocale: "hu",
    messages: messages
})

export function activeLang(): "hu" | "en" {
    return i18n.global.locale.value
}
export function setLang(locale: "hu" | "en") {
    localeStore().locale = locale
    i18n.global.locale.value = locale
    setCommonDatalists(locale)
}
if (activeLang() !== "hu" && activeLang() !== "en") {
    i18n.global.locale.value = "hu"
}

app.use(i18n)

app.component("font-awesome-icon", FontAwesomeIcon)

app.component('NavBar', NavBar)

app.mount('#app')

export const windowWidth = ref(window.innerWidth)

export var brandTypesMap: Map<String, Array<String>> | null = null

if (credentialStore().isAdmin) {
    console.log("Fetch brands")
    const suggestionService = new SuggestionService()
    suggestionService.brandTypes()
        .then((suggestions: Map<String, Array<String>>) => {
            const brandDatalist = document.getElementById("brand-datalist")
            brandTypesMap = new Map(Object.entries(suggestions))
            brandTypesMap.forEach((_, brand) => {
                let option = document.createElement('option')
                option.value = brand.toString()
                brandDatalist.appendChild(option)
            })
        })
        .catch(e => console.error(e))
    suggestionService.works()
        .then((suggestions: Array<String>) => {
            const worksDatalist = document.getElementById("works-datalist")
            suggestions.forEach(suggestion => {
                let option = document.createElement('option')
                option.value = suggestion.toString()
                worksDatalist.appendChild(option)
            })
        })
        .catch(e => console.error(e))
}

const typesDatalist = document.getElementById("model-datalist")
export function setTypeDatalist(brand: String) {
    typesDatalist.innerHTML = "";
    (brandTypesMap?.get(brand) ?? []).forEach((type: String) => {
        let option = document.createElement('option');
        option.value = type.toString();   
        typesDatalist.appendChild(option);
    })
}

function setCommonDatalists(locale: 'hu' | 'en') {
    function setUpDatalist(options: CommonOptions) {
        const datalist = document.getElementById(options.datalist)
        datalist.innerHTML = "";
        options.localeValues(locale).forEach(value => {
            let option = document.createElement('option');
            option.value = value.toString();   
            datalist.appendChild(option);
        })
    }
    setUpDatalist(commonTypes);
    setUpDatalist(commonFuel);
    setUpDatalist(commonColors);
}
setCommonDatalists(localeStore().locale)

emitter.on("auth-error", (e: AuthError) => {
    credentialStore().logout()
    router.push({ name: "login" })
    const message = e.expired ? "message.auth.expired" : "message.auth.error"
    emitter.emit("log", new Message(message, MessageType.Success))
})