<script lang="ts">

import {ExpandingView, SuggestedWork} from '../../model/model';

export default {
    props: {
        work: {
          type: Object as () => ExpandingView<SuggestedWork>
        },
        editEnabled: Boolean
    }
}
</script>

<template>
  <div class="card" @click="work?.toggle()">
    <div class="work-name-view"><font-awesome-icon icon="wrench" /> {{ work.data.name }}</div>
    <div><font-awesome-icon icon="calendar-days" /> {{ work.data.date }}</div>
    <div v-if="work?.expanded">
      <div>{{ $t("work.details.components") + ':' +  work.data.components }}</div>
      <div v-if="editEnabled">
        <!--<button v-if="editEnabled" @click.stop="$emit('edit-work', work.data)"><font-awesome-icon class="nav-icon" icon="edit" /> {{ $t("work.update") }}</button>-->
        <button v-if="editEnabled" @click.stop="$emit('delete-work', work.data.id)"><font-awesome-icon class="nav-icon" icon="trash" /> {{ $t("work.delete") }}</button>
      </div>
    </div>
  </div>  
</template>

<style scoped>
.work-name-view {
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.card>div:not(.work-name-view)>span {
  padding-left: 1rem;
}

.card>div:not(.work-name-view) {
  padding-left: 0.5rem;
}
</style>