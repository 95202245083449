<script setup lang="ts">

import UserService from "../../services/UsersService";
import {emitter} from "../../main";
import {Message, MessageType} from "../global/MessageView.vue";

const userService = new UserService()

const props = defineProps<{
  userId: string,
  givenConsent: boolean,
  canEnable: boolean
}>()

const emit = defineEmits(["consentChanged"])

function toggleConsent() {
  if (props.givenConsent) {
    disableConsent()
  } else {
    createConsent()
  }
}

function createConsent() {
  userService.createEmailConsent(props.userId)
      .then(() => {
        emit("consentChanged", true)
        emitter.emit("log", new Message("message.user.consent.created", MessageType.Success))
      }).catch((e) => {
        console.error("Failed to create email consent", e)
        emitter.emit("log", new Message("message.user.consent.createFailed", MessageType.Error))
      })
}

function disableConsent() {
  userService.deleteEmailConsent(props.userId)
      .then(() => {
        emit("consentChanged", false)
        emitter.emit("log", new Message("message.user.consent.deleted", MessageType.Success))
      })
      .catch((e) => {
        console.error("Failed to delete email consent", e)
        emitter.emit("log", new Message("message.user.consent.deleteFailed", MessageType.Error))
      })
}
</script>

<template>
  <div>
    {{ $t(props.givenConsent ? "message.user.consent.present" : "message.user.consent.missing") }}
    <button v-if="canEnable" @click="toggleConsent">{{ $t(props.givenConsent ? "message.user.consent.delete" : "message.user.consent.create") }}</button>
  </div>
</template>

<style scoped>
div {
  font-size: large;
}

button {
  background: var(--global-color-background);
  display: inline-block;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0 8px rgba(0,0,0,.6);
  padding: 0.5rem 1rem;
}

button:not([disabled]):hover {
  background: var(--global-color-selected);
}
</style>