<script setup lang="ts">

import {Car} from '../../model/model';
import {commonColors, commonTypes} from "../../util/commonOptions";
import {activeLang} from "../../main";
import {ref} from "vue";

defineProps<{
  car: Car
}>()

const expanded = ref(false)
</script>

<template>
  <div class="card">
    <div class="title-row">
      <div class="car-plate-view"><font-awesome-icon icon="car" /> {{ car.plate }}</div>
      <div @click.stop="expanded = !expanded">{{ expanded ? '▲' : '▼' }}</div>
    </div>
    <div>{{ car.brand }} {{ car.model }} {{ (car.year) ? `(${car.year})` : '' }}</div>
    <div>{{ commonColors.localName(activeLang(), car.color) }} {{ commonTypes.localName(activeLang(), car.type) }}</div>
    <div v-if="expanded">
      <div><font-awesome-icon icon="calendar-check" /> {{ car.licenseExpiry ?? '-' }}</div>
      <div><font-awesome-icon icon="wrench" /> {{ car.nextService ?? '-' }}</div>
    </div>
  </div>  
</template>

<style scoped>
svg {
  vertical-align: text-top;
  margin-right: 0.2rem;
}

.title-row {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.title-row div {
  display: inline-block;
}

.car-plate-view {
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.card>div:not(.title-row) div {
  margin-bottom: 0.1rem;
}

.card>div:not(.title-row) {
  padding-left: 0.5rem;
}
</style>