<script lang="ts">
import {CarWithWorks, EditState, MutableCar, SuggestedWork, ValidationError} from '../model/model';

import CarService from '../services/CarService';
import {Message, MessageType} from './global/MessageView.vue';
import EditableInput from './util/EditableInput.vue';

import ConfirmService, {DialogMessage} from '../services/ConfirmService';

import {setTypeDatalist} from '../main';
import {commonColors, commonFuel, commonTypes} from '../util/commonOptions';
import SuggestedWorksDetails from './suggestions/SuggestedWorksDetails.vue';
import WorksDetails from './work/WorksDetails.vue';
import {clearErrorAt, setErrorAt} from '../util/validationErrors';
import CardInfoPanel from './car/CarInfoPanel.vue';

const carService = new CarService()

export default {
    props: {
      initialCar: {
        type: Object as () => CarWithWorks
      },
      editEnabled: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        newCar: this.initialCar.car.id === "",
        savedCar: this.initialCar.car,
        carData: MutableCar.of(this.initialCar.car),
        state: this.initialCar.car.id === "" ? EditState.Edit : EditState.View,
        confirmService: new ConfirmService(this.emitter),
        saveError: null,
        commonTypes: commonTypes,
        commonFuel: commonFuel,
        commonColors: commonColors
      }
    },
    methods: {
      startEdit() {
        this.state = EditState.Edit
        this.brandChanged()
      },
      async saveEdit() {
        this.state = EditState.Saving
        this.clearSaveError()
        try {
          const updated = await carService.updateOrCreateCar(this.carData)
          this.savedCar = updated
          this.carData = MutableCar.of(updated)
          this.state = EditState.View
          this.$emit('plate-change', updated.plate)
          const message = this.newCar ? "message.car.created.ok" : "message.car.updated.ok"
          this.emitter.emit("log", new Message(message, MessageType.Success))
          this.newCar = false
        } catch (e) {
          if (e instanceof ValidationError) {
            this.setSaveError(e as ValidationError)
          } else {
            this.clearSaveError()
          }

          this.state = EditState.Edit
          const message = this.newCar ? "message.car.created.fail" : "message.car.updated.fail"
          this.emitter.emit("log", new Message(message, MessageType.Error))
        }
      },
      clearSaveError() {
        clearErrorAt("#car-details")
      },
      setSaveError(error: ValidationError) {
        setErrorAt("#car-details", "tr", error)
      },
      revert() {
        this.clearSaveError()
        if (this.newCar) {
          this.$emit('car-unselect', this.savedCar)
        } else {
          this.state = EditState.View
          this.carData = MutableCar.of(this.savedCar)
        }
      },
      triggerDelConfirm() {
        this.state = EditState.View
        this.confirmService.askToConfirm(
          new DialogMessage("message.car.deleted.confirm", {plate: this.savedCar.plate}, "modal.cancel", "modal.delete"),
          () => this.carDelConfirm(),
          () => this.state = EditState.View
        )
      },
      cancelDel() {
        this.state = EditState.Saving
        this.confirmData = null
      },
      async carDelConfirm() {
        this.state = EditState.View
        this.confirmData = null
        try {
          await carService.deleteCar(this.savedCar)
          this.$emit('cars-changed')
          this.$emit('car-unselect', this.savedCar)
          this.emitter.emit("log", new Message("message.car.deleted.ok", MessageType.Success))
        } catch (e) {
          this.state = EditState.View
          this.emitter.emit("log", new Message("message.car.deleted.fail", MessageType.Error))
        }
      },
      async getReport() {
        await carService.generateReport(this.savedCar)
      },
      brandChanged() {
        setTypeDatalist(this.carData.brand)
      },
      completeSuggested(suggested: SuggestedWork) {
        this.$refs.works.addNew(suggested)
      },
      suggestionAdded(suggested: SuggestedWork) {
        this.$refs.suggestions.delSuggestion(suggested)
      }
    },
    computed: {
      editShown() { return this.state === EditState.View },
      editMode() { return this.state === EditState.Edit || this.state === EditState.Saving },
      savePending() { return this.state === EditState.Saving }
    },
    components: { EditableInput, WorksDetails, SuggestedWorksDetails, CardInfoPanel }
}
</script>

<template>
  <div>
    <div v-if="editEnabled" id="actions">
      <button class="action-button" :title="$t('car.action.generateReport')" @click="getReport"><font-awesome-icon class="nav-icon" icon="file-export" /></button>
      <button id="edit-button" class="action-button" v-if="editShown" @click="startEdit" :title="$t('car.action.edit')"><font-awesome-icon class="nav-icon" icon="pen-to-square" /></button>
      <button id="delete-button" class="action-button" v-if="editShown" @click="triggerDelConfirm" :title="$t('car.action.delete')"><font-awesome-icon class="nav-icon" icon="trash" /></button>
      <button id="save-button" class="action-button" v-if="editMode" :disabled="savePending" @click="saveEdit" :title="$t('car.action.save')"><font-awesome-icon class="nav-icon" icon="floppy-disk" /></button>
      <button id="cancel-button" class="action-button" v-if="editMode" :disabled="savePending" @click="revert" :title="$t('car.action.undo')"><font-awesome-icon class="nav-icon" icon="clock-rotate-left" /></button>
    </div>
    <CardInfoPanel :car-data="this.carData" :editable="this.editMode" @brand-changed="brandChanged" />
    <div id="works-data" v-if="savedCar.id !== ''">
      <WorksDetails ref="works" :car-id="savedCar.id" :edit-enabled="editEnabled" :works="initialCar?.works" />
      <SuggestedWorksDetails ref="suggestions" :car-id="savedCar.id" :edit-enabled="editEnabled" :suggestions="initialCar?.suggestions" />
    </div>
  </div>
</template>

<style>
table#car-details input {
  width: 200px
}
</style>

<style scoped>
table#car-details, #actions {
  display: inline-block;
  vertical-align: top;
}

#actions {
  padding-top: 0.5rem;
}

.work-button {
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

th, td {
  font-size: large;
}

table#car-details th {
  text-align: right;
  font-weight: bold;
  padding-left: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
</style>