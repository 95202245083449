<script lang="ts">
import { setLang } from '../main';
import SettingsTable from "../components/SettingsTable.vue";

export default {
  components: {SettingsTable},
  methods: {
    selectLang(lang: "en" | "hu") {
      setLang(lang)
    }
  }
}
</script>

<template>
  <main>
    <h1>{{ $t("settings.settings") }}</h1>
    <button @click="this.selectLang('en')">English</button>
    <button @click="this.selectLang('hu')">Magyar</button>
    <SettingsTable />
  </main>
</template>
