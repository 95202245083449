<script lang="ts">
import {ExpandingView, MutableSuggestedWork, SuggestedWork} from '../../model/model';
import {formatDate} from '../../util/format';
import EntityTable from './EntityTable.vue';
import EditableInput from '../util/EditableInput.vue';
import ExpandingEntityTable from './ExpandingEntityTable.vue';

export default {
    props: {
      suggestions: Array<ExpandingView<SuggestedWork>>,
      createdSuggestion: {
          type: [MutableSuggestedWork, null]
      },
      editEnabled: Boolean
    },
    data() {
      return {
        edited: null
      }
    },
    methods: {
      formatDate(date: String): String {
        return formatDate(date)
      }
    },
    emits: [
      "complete-suggested", "edit-work", "save-work", "delete-work"
    ],
    components: { EntityTable, ExpandingEntityTable, EditableInput }
}
</script>

<template>
  <ExpandingEntityTable :entities="suggestions" :cols="4"
    loading-message="suggestedWork.load.inProgress" empty-message="suggestedWork.load.empty" error-message="suggestedWork.load.fail">
    <template #columns>
      <col width="25%" />
      <col width="25%" />
      <col width="50%" />
      <col />
    </template>
    <template #headers>
      <th>{{ $t("work.details.date") }}</th>
      <th>{{ $t("work.details.name") }}</th>  
      <th>{{ $t("work.details.components") }}</th>
    </template>
    <template #entity="suggested">
      <td>{{ formatDate(suggested.date) }}</td>
      <td>{{ suggested.name }}</td>
      <td>{{ suggested.components.join(", ") }}</td>
    </template>
    <template #details="work">
      <table class="nested-table">
        <tr>
          <th>{{ $t("work.details.date") }}:</th>
          <td data-field="date"><EditableInput v-model="work.date" :editable="editEnabled" type="date" /></td>
        </tr>
        <tr>
          <th>{{ $t("work.details.name") }}:</th>
          <td data-field="name"><EditableInput v-model="work.name" :editable="editEnabled" raw-datalist="works-datalist" /></td>
        </tr>
        <tr>
          <th>{{ $t("work.details.components") }}:</th>
          <td data-field="components">
            <ul v-if="!editEnabled">
              <li v-for="component in work.components">{{ component }}</li>
            </ul>
            <table v-if="editEnabled" class="editable-list">
              <tr v-for="(_,index) in work.components">
                <td><button @click="work.components.splice(index, 1);"  style="display: inline-block"><font-awesome-icon icon="minus-circle"/></button></td>
                <td><EditableInput v-model="work.components[index]" :editable="editEnabled" /></td>
              </tr>
              <tr>
                <td><button @click="work.components.push('');" style="display: inline-block"><font-awesome-icon icon="plus-circle"/></button></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <button v-if="editEnabled" class="work-button" @click="$emit('complete-suggested', work)"><font-awesome-icon class="nav-icon" icon="edit" /> {{ $t("suggestedWork.action.logSuggestionDone") }}</button>
      <button v-if="editEnabled" class="work-button" @click="$emit('edit-work', work)"><font-awesome-icon class="nav-icon" icon="edit" /> {{ $t("work.update") }}</button>
      <button v-if="editEnabled" class="work-button" @click="$emit('delete-work', work.id)"><font-awesome-icon class="nav-icon" icon="trash" /> {{ $t("work.delete") }}</button>
    </template>
    <template #footer>
      <tr v-if="createdSuggestion" id="new-row" class="data-row">
        <td data-field="date"><EditableInput v-model="createdSuggestion.date" :editable="true" type="date" /></td>
        <td data-field="name"><EditableInput v-model="createdSuggestion.name" :editable="true" type="string"  raw-datalist="works-datalist" /></td>
        <td data-field="components"><EditableInput v-model="createdSuggestion.components" :editable="true" type="array" /></td>
      </tr>
    </template>
  </ExpandingEntityTable>
</template>

<style scoped>
.work-button {
  padding: 1rem;
  margin: 0.5rem;
  cursor: pointer;
}

.nested-table .editable-list {
  padding-left: 0;
  min-width: 300px;
}

.nested-table ul {
  padding-left: 1rem;
}
</style>