<script lang="ts">
import {Fail, Loading, Ok, Result, User} from '../model/model';
import router from '../router';
import UserService from '../services/UsersService';

import {Message, MessageType} from '../components/global/MessageView.vue';
import UserDetails from '../components/UserDetails.vue';
import UsersListing from "../components/reactive/UsersListing.vue";
import {windowWidth} from "../main";

export const userService = new UserService();

declare interface BaseData {
  query: String,
  loadedUsers: Result<String, Array<User>>
}

export default {
  computed: {
    windowWidth() {
      return windowWidth
    }
  },
    data(): BaseData {
      return {
        query: "" as String,
        loadedUsers: new Ok([], false) as Result<String, Array<User>>
      }
    },
    methods: {
      searchUsers: async function () {
        const usedQuery = this.query
        console.log("Searching for users " + usedQuery)
        this.loadedUsers = new Loading(usedQuery) as Result<String, Array<User>>
        
        try {
          const userResult = await userService.searchUsers(usedQuery)
          this.setResult(usedQuery, new Ok(userResult.items, userResult.limited))
        } catch (e) {
          this.emitter.emit("log", new Message("user.load.fail", MessageType.Error))
          this.setResult(usedQuery, new Fail(e))
        }
      },
      setResult: function(query: String, result: Result<String, Array<User>>) {
        const current = this.loadedUsers
        if ((current as Loading<String>)?.query === query) {
          this.loadedUsers = result
        }
      },
      userSelect: function(user: User) {
        router.push({ name: "viewUser", params: {userId: user.id} });
      },
      createUser() {
        router.push({ name: "createUser" });
      }
    },
    mounted() {
      this.searchUsers()
    },
    components: {UsersListing, UserDetails }
}
</script>

<template>
  <main>
    <div id="title-container">
      <button id="new-user-button" @click="createUser" :title="$t('user.new')"><font-awesome-icon class="nav-icon" icon="user-plus" /></button>
      <h1 id="title-label">{{ $t("global.users") }}</h1>
      <h1>></h1>
      <h1 id="search-label">{{ $t("global.search") }}</h1>
      <input id="user-search" v-model="query" class="search" type="text" v-on:input="searchUsers" :placeholder="$t('user.search.placeholder')">
    </div>
    <UsersListing :users="this.loadedUsers" @user-select="this.userSelect" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

#title-label {
  font-weight: bold;
  cursor: pointer;
}

h1#search-label {
  font-style: italic;
  margin-right: 1rem;
}

input {
  max-width: 100%;
  font-size: x-large;
  padding: 0.5rem;
  width: 500px;
  display: inline-block;
  vertical-align: bottom;
}

#new-user-button {
  font-size: x-large;
  display: inline-block;
  background: var(--global-color-background);
  border: none;
  color: white;
  cursor: pointer;
  font-size: x-large;
  width: 50px;
  height: 50px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  vertical-align: bottom;
}

#new-user-button:hover {
  background: var(--global-color-selected);
}
</style>
