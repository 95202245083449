<script setup lang="ts">
import {RouterView} from 'vue-router';
import MessageView from './components/global/MessageView.vue';
import ModalDialog from './components/global/ModalDialog.vue';
import NavBar from './components/global/NavBar.vue';
import {credentialStore} from './stores/credentials';
import {onBeforeUnmount, onMounted} from "vue";
import {windowWidth} from "./main";

const resizeListener = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener("resize", resizeListener)
})
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeListener)
})
</script>

<template>
  <NavBar v-if="credentialStore().loggedIn"></NavBar>
  <RouterView id="router-content" />
  <MessageView />
  <ModalDialog />
  <datalist id="brand-datalist"></datalist>
  <datalist id="works-datalist"></datalist>
  <datalist id="model-datalist"></datalist>
  <datalist id="type-datalist"></datalist>
  <datalist id="fuel-datalist"></datalist>
  <datalist id="color-datalist"></datalist>
</template>

<style>
html, body, #app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

main {
  width: calc(100% - 250px);
  padding: 1rem;
}

#router-content {
  position: absolute;
  left: 250px;
}

@media (max-width:480px)  {
  main {
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  #router-content {
    position: relative;
    left: auto;
  }
}

table.main {
  width: 100%;
  margin: 0;
  color: white;
  table-layout: fixed;
}

table.main thead {
  background: var(--global-color-background);
  font-size: large;
}

table.main tr.data-row>td {
  padding: 0.3rem 0.5rem;
}

table.main tr.data-row:not(#new-row)>td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

table.main thead>tr>th {
  font-weight: bold;
  padding: 0.4rem;
}

table.main tr.data-row {
  cursor: pointer;
}

table.main tbody>tr.data-row:hover {
  background-color: var(--global-color-selected);
}

table.main tbody>tr.data-row:hover a {
  color: white;
}

table.main tr.message {
  background-color: var(--global-color-row-b);
  color: black;
}

table.main tbody>tr.data-row:nth-of-type(even):not(:hover) {
  background-color: var(--global-color-row-a);
  color: black;
}

table.main tbody>tr.data-row:nth-of-type(odd):not(:hover) {
  background-color: var(--global-color-row-b);
  color: black;
}

table.main tr.data-row.selected {
  background-color: var(--global-color-selected) !important;
  color: white !important;
}

table.main td.center {
  text-align: center;
}

table.main tr.message td {
  text-align: center;
  font-weight: bold;
}

#new-row>td {
  vertical-align: super;
  text-align: center;
}

.nested-row>td {
  padding: 0.3rem;
  padding-left: 3rem;
  padding-right: 0.5rem;
  background: var(--global-color-selected);
}

.nested-row table th {
  font-weight: bold;
  text-align: right;
}

.action-button {
  background: var(--global-color-background);
  border: none;
  color: white;
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-size: x-large;
  width: 70px;
  height: 70px;
  margin-left: 0.4rem;
  box-shadow: 0px 0px 8px rgba(0,0,0,.6);
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.action-button:not([disabled]):hover {
  background: var(--global-color-selected);
}

.card {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 3px solid var(--global-color-selected);
  border-radius: 5px;
  padding: 1rem;
}

/*.card:hover {
//  background-color: var(--global-color-selected);
}*/

.card-message {
  width: 100%;
  text-align: center;
  border-bottom: 3px solid var(--global-color-selected);
  line-height: 0.1em;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.card-message>span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: var(--color-background);
}
</style>

<style scoped>
header {
  line-height: 1.5;
  --max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;
  }
}
</style>