<script setup lang="ts">

import type {Sort} from "../../model/model";
import {SortDir} from "../../model/model";
import {computed} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps<{
  header: String,
  field: String,
  sort: Sort | null,
  sortable: Boolean
}>()

const icon = computed(() => {
  if (props.sort?.field !== props.field) {
    return "sort"
  } else if (props?.sort?.direction === SortDir.ASC) {
    return "sort-up"
  } else {
    return "sort-down"
  }
})

defineEmits(["trigger-sort"])
</script>

<template>
  <th @click="$emit('trigger-sort', field)">{{ $t(props.header.toString()) }} <font-awesome-icon :icon="icon" v-if="sortable"/></th>
</template>

<style scoped>
th {
  cursor: pointer;
}
</style>