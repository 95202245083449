import {
    Car,
    CarData,
    CarWithWorks,
    SuggestedWork,
    SuggestedWorkData,
    User,
    UserData,
    UserWithCars,
    Work,
    WorkData
} from "./model";

export function dtoToUser(body: any): User {
    return new UserData(body.id, body.name, body.phone, body.email, body.hasEmailConsent)
}

export function dtoToCar(body: any): Car {
    return new CarData(
        body.id, body.owner, body.brand, body.plate, body.model, body.vin, body.motorNumber,
        body.licenseExpiry, body.nextService, body.color, body.year, body.displacementQcm,
        body.performanceKw, body.fuel, body.type
    )
}

export function dtoToUserWithCars(body: any): UserWithCars {
    return new UserWithCars(dtoToUser(body.user), body.cars.map((c: any) => dtoToCar(c)))
}

export function dtoToWork(body: any): Work {
    return new WorkData(body.id, body.car, body.date, body.name, body.kilometers, body.components, body.comment)
}

export function dtoToSuggestedWork(body: any): SuggestedWork {
    return new SuggestedWorkData(body.id, body.car, body.date, body.name, body.components)
}

export function dtoToCarWithWorks(body: any): CarWithWorks {
    return new CarWithWorks(
        dtoToCar(body.car),
        body.works.map((c: any) => dtoToWork(c)),
        body.suggestedWorks.map((c: any) => dtoToSuggestedWork(c))
    )
}