<script lang="ts">
import router from '../router';
import CarService from '../services/CarService';

import { Message, MessageType } from '../components/global/MessageView.vue';
import CarDetails from '../components/CarDetails.vue';
export const carService = new CarService();

export default {
    props: {
      carId: String
    },
    data() {
      return {
        loadedCar: null,
        headerLabel: this.$t("car.loading")
      }
    },
    methods: {
      loadCar: async function () {
        try {
          this.loadedCar = await carService.userCarWithWorks(this.carId)
          this.headerLabel = this.loadedCar.car.plate
        } catch (e) {
          this.emitter.emit("log", new Message("car.loadFail", MessageType.Error))
        }
      },
      backToProfile() {
        router.back()
      }
    },
    mounted() {
      this.loadCar()
    },
    components: { CarDetails }
}
</script>

<template>
  <main>
    <div id="title-container">
      <h1>{{ headerLabel }}</h1>
    </div>
    <CarDetails v-if="loadedCar" :initial-car="loadedCar" @car-unselect="backToProfile" @plate-change="" :editEnabled="false" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

#title-label {
  font-weight: bold;
  cursor: pointer;
}

h1#search-label {
  font-style: italic;
  margin-right: 1rem;
}

input {
  font-size: x-large;
  padding: 0.5rem;
  width: 500px;
  display: inline-block;
}
</style>
