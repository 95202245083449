import {AdminLogin, JwtToken, OtpLogin} from "../model/model";
import {adminGet, anonCaptchaHeaders, anonDelete, getAnon, postAnon} from "./http";

export default class LoginService {

    public async linkLogin(link: String): Promise<JwtToken> {
        return await getAnon(`login/${link}`)
    }

    public async userLogin(otp: OtpLogin): Promise<JwtToken> {
        return await postAnon("tokens", otp)
    }

    public async requestOtp(identifier: String, token: string): Promise<String> {
        return await postAnon("otp", identifier, anonCaptchaHeaders(token))
    }

    public async adminLogin(username: String, password: String): Promise<JwtToken> {
        return await postAnon("admin/tokens", new AdminLogin(username, password))
    }

    public async refresh(): Promise<String> {
        return await adminGet("admin/tokens/refresh")
    }

    public async emailUnsubscribe(userId: String, emailConsent: String): Promise<any> {
        console.log(`DELETE users/${userId}/consent/${emailConsent}`)
        return await anonDelete("users/" + userId + '/consent/' + emailConsent)
    }

}