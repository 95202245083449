<script lang="ts">
import { activeLang } from '../../main'
import { CommonOptions } from '../../util/commonOptions'

enum PropType {
  Tel, Email, Int, Year, Date, Pass, Array, Capital, LocalisedOption, String, Kw
}

function htmlInputType(type: PropType): string {
  switch (type) {
    case PropType.Tel: return "tel"
    case PropType.Email: return "email"
    case PropType.Int: return "number"
    case PropType.Year: return "number"
    case PropType.Pass: return "password"
    case PropType.Date: return "date"
    case PropType.Array: return ""
    default: return "text"
  }
}

function parseInputType(type: string): PropType {
  switch (type) {
    case "tel": return PropType.Tel
    case "email": return PropType.Email
    case "int": return PropType.Int
    case "year": return PropType.Year
    case "pass": return PropType.Pass
    case "date": return PropType.Date
    case "array": return PropType.Array
    case "capital": return PropType.Capital
    case "localOpt": return PropType.LocalisedOption
    case "kw": return PropType.Kw
    default: return PropType.String
  }
}

function render(type: PropType, options: CommonOptions | null, value: Object): string {
  switch (type) {
    case PropType.Array: return (value as Array<any>)?.join(',') ?? ""
    case PropType.LocalisedOption:
      return (options !== null && value !== null) ?
          options.localName(activeLang(), value.toString()).toString() :
          value?.toString() ?? ""
    default: return value?.toString() ?? ""
  }
}

function renderTitle(type: PropType, value: Object): string | null {
  if (type === PropType.Kw) {
    return `${1.341 * (value as number)} HP`
  } else {
    return null
  }
}

function parse(type: PropType, options: CommonOptions | null, value: string): any {
  switch (type) {
    case PropType.Array:
      return value.split(',')
    case PropType.LocalisedOption:
      return options?.parseLocale(activeLang(), value) ?? value
    default: return value
  }
}

export default {
  props: {
    modelValue: {},
    type: {
      type: String,
      default: "string"
    },
    editable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [CommonOptions, null]
    },
    rawDatalist: {
      type: [String, null]
    }
  },
  data() {
    return {
      propType: parseInputType(this.type),
      stringValue: "",
      datalist: this.rawDatalist ?? this.options?.datalist,
      title: ""
    }
  },
  computed: {
    htmlType(): string {
      return htmlInputType(this.propType)
    },
    upper(): Boolean {
      return this.propType === PropType.Capital
    },
    min(): string {
      return (this.propType === PropType.Year) ? "1900" : ""
    },
    max(): string {
      return (this.propType === PropType.Year) ? "2050" : ""
    }
  },
  methods: {
    parsedValue(target: any) {
      this.stringValue = (target as HTMLInputElement).value
      this.$emit('update:modelValue', parse(this.propType, this.options, this.stringValue))
    }
  },
  mounted() {
    this.stringValue = render(this.propType, this.options, this.modelValue)
    this.title = renderTitle(this.propType, this.modelValue)
  },
  watch: {
    modelValue: function() {
      this.stringValue = render(this.propType, this.options, this.modelValue)
      this.title = renderTitle(this.propType, this.modelValue)
    }
  },
  emits: ["valueChange", "update:modelValue"]
}
</script>

<template>
  <input
    :value="this.stringValue" @input="parsedValue($event.target)" :readonly="!this.editable" :title="title"
    :type="this.htmlType" :list="datalist" :class="this.upper ? 'upper' : ''" :min="this.min" :max="this.max">
  <span class="validation-message"></span>
</template>

<style scoped>
input {
  font-size: inherit;
  width: 100%;
}

.validation-message {
  color: crimson;
  padding-left: 0.5rem;
}

input.upper {
  text-transform: uppercase;
}

input[readonly] {
  background: none;
  border: none;
  color: inherit;
}

input[readonly]:active {
  background: none;
  border: none;
  color: inherit;
}
</style>