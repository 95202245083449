<script lang="ts">
import {Car, Fail, Loading, Ok, Result, Sort} from '../model/model';
import router from '../router';
import CarService from '../services/CarService';

import {Message, MessageType} from '../components/global/MessageView.vue';
import CarsTable from '../components/table/CarsTable.vue';
import CarCard from "../components/card/CarCard.vue";
import CarsListing from "../components/reactive/CarsListing.vue";

export const carService = new CarService();

export default {
    data() {
      return {
        query: "" as String,
        loadedCars: new Ok([]) as Result<String, Array<Car>>,
        sort: null as Sort | null
      }
    },
    methods: {
      searchCars: async function () {
        const usedQuery = this.query
        console.log("Searching for cars " + usedQuery)
        this.loadedCars = new Loading(usedQuery)
        
        try {
          const carsResult = await carService.searchCars(usedQuery, this.sort)
          this.setResult(usedQuery, new Ok(carsResult.items, carsResult.limited))
        } catch (e) {
          this.emitter.emit("log", new Message("car.load.fail", MessageType.Error))
          this.setResult(usedQuery, new Fail(e))
        }
      },
      setResult: function(query: String, result: Result<String, Array<Car>>) {
        if (this.loadedCars.query === query) {
          this.loadedCars = result
        }
      },
      carSelect: function(car: Car) {
        router.push({ name: "viewCar", params: {carId: car.id} });
      },
      triggerSort: function (sort: Sort) {
        this.sort = sort
        if (this.loadedCars.isLoading()) {
          this.searchCars()
        } else if (this.loadedCars.isOk()) {
          const asOk = this.loadedCars as Ok<Array<Car>>
          if (asOk.limited) {
            this.searchCars()
          } else {
            const sorted = carService.sortLocally(sort, asOk.data)
            this.loadedCars = new Ok(sorted, false)
          }
        }
      }
    },
    mounted() {
      this.searchCars()
    },
    components: {CarsListing, CarCard, CarsTable }
}
</script>

<template>
  <main>
    <div id="title-container">
      <h1 id="title-label">{{ $t("global.cars") }}</h1>
      <h1>></h1>
      <h1 id="search-label">{{ $t("global.search") }}</h1>
      <input id="car-search" v-model="query" class="search" type="text" v-on:input="searchCars" :placeholder="$t('car.search.placeholder')">
    </div>
    <CarsListing :cars="loadedCars" @car-select="carSelect" :sortable="true" @trigger-sort="triggerSort" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

#title-label {
  font-weight: bold;
  cursor: pointer;
}

h1#search-label {
  font-style: italic;
  margin-right: 1rem;
}

input {
  max-width: 100%;
  font-size: x-large;
  padding: 0.5rem;
  width: 500px;
  display: inline-block;
}
</style>
