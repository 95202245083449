"use strict";

import { dtoToUser, dtoToUserWithCars } from "../model/dto";
import { LimitedResult, User, UserWithCars } from "../model/model";
import { adminDelete, adminGet, adminPost, adminPut } from "./http";

export default class UserService {

    public async fetchUserWithCars(id: string): Promise<UserWithCars> {
        const user = await adminGet(`users/${id}`, {details: "cars"})
        return dtoToUserWithCars(user)
    }

    public async fetchUser(id: string): Promise<User> {
        const user = await adminGet(`users/${id}`)
        return dtoToUser(user)
    }

    public async searchUsers(part: string): Promise<LimitedResult<User>> {
        return await adminGet("users/", {search: part})
    }

    public async updateUser(user: User): Promise<User> {
        const updated = await adminPut("users/" + user.id, user)
        return dtoToUser(updated)
    }

    public async createUser(user: User): Promise<User> {
        const updated = await adminPost("users/", user)
        return dtoToUser(updated)
    }

    public async updateOrCreateUser(user: User): Promise<User> {
        if (user.id === "") {
            return this.createUser(user)
        } else {
            return this.updateUser(user)
        }
    }

    public async deleteUserById(userId: String): Promise<any> {
        return await adminDelete(`users/${userId}`)
    }

    public async deleteUser(user: User): Promise<any> {
        return await this.deleteUserById(user.id)
    }

    public async deleteEmailConsent(userId: String): Promise<any> {
        return await adminDelete(`users/${userId}/consent`)
    }

    public async createEmailConsent(userId: String): Promise<any> {
        return await adminPost(`users/${userId}/consent`, {})
    }
}