import { InvalidField, ValidationError } from "../model/model"

export function clearErrorAt(selector: string) {
    document.querySelectorAll(`${selector} .validation-message`).forEach(span => span.textContent = "")
}

export function setErrorAt(parentsQuery: string, selfQuery: string, error: ValidationError) {
    error.errors.forEach((error: InvalidField) => {
        const span = document.querySelector(`${parentsQuery} ${selfQuery}[data-field="${error.property}"] .validation-message`)
        if (span != null) {
            span.textContent = error.localised.toString()
        }
    })
}