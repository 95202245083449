<script setup lang="ts" generic="T">
import {computed} from 'vue';
import {Ok, Result, User} from '../../model/model';

const props = defineProps<{
  entities: Result<String, Array<T>>,
  loadingMessage: string,
  emptyMessage: string,
  errorMessage: string,
  limitedMessage: string | null
}>()

defineEmits(['entity-select'])

const cardMessage = computed(() => {
  if (props.entities.isFail()) {
    return props.errorMessage
  } else if (props.entities.isLoading()) {
    return props.loadingMessage
  } else {
    const asOk = props.entities as Ok<Array<User>>
    if (asOk.limited) {
      return props.limitedMessage
    } else if (asOk.data.length === 0) {
      return props.emptyMessage
    } else {
      return null
    }
  }
})
</script>

<template>
  <ul style="margin-left: 0; padding-left: 0;">
    <div v-for="(entity, i) in (entities as Ok<Array<T>>)?.data" v-bind:key="i" @click="$emit('entity-select', entity)">
      <slot name="entity" v-bind="entity"></slot>
    </div>
    <h2 class="card-message" v-if="cardMessage"><span>{{ $t(cardMessage) }}</span></h2>
  </ul>
</template>