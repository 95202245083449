"use strict";

import {dtoToCar, dtoToCarWithWorks} from "../model/dto";
import {Car, CarWithWorks, LimitedResult, Sort, SortDir, User} from "../model/model";
import {adminDelete, adminDownload, adminGet, adminPost, adminPut} from "./http";

type CarComparator = (a: Car, b: Car) => number

type CarSorts = Record<string, CarComparator>
const sortFuns: CarSorts = {
    "plate": carPropSort((c: Car) => c.plate),
    "brand": carPropSort((c: Car) => c.brand),
    "model": carPropSort((c: Car) => c.model),
    "license_expiry": carPropSort((c: Car) => c.licenseExpiry),
    "next_service": carPropSort((c: Car) => c.nextService)
}

function carPropSort(prop: (Car) => String | null): CarComparator {
    return (a: Car, b: Car) => {
        const aVal = prop(a)?.toUpperCase()
        const bVal = prop(b)?.toUpperCase()
        if (!aVal) return 1
        else if (!bVal) return -1
        else return aVal < bVal ? -1 : 1
    }
}

export default class UserService {

    public async userCarWithWorks(id: string): Promise<CarWithWorks> {
        const user = await adminGet(`users/self/cars/${id}`, {details: "works"})
        return dtoToCarWithWorks(user)
    }

    public async fetchCarWithWorks(id: string): Promise<CarWithWorks> {
        const user = await adminGet(`cars/${id}`, {details: "works"})
        return dtoToCarWithWorks(user)
    }

    public async fetchCar(id: String): Promise<Car> {
        const body = await adminGet(`cars/${id}`, {})
        return dtoToCar(body)
    }

    public async searchCars(part: string, sort: Sort | null): Promise<LimitedResult<Car>> {
        return await adminGet("cars/", {search: part, sort: sort?.field, sort_dir: sort?.direction})
    }

    public async carsOfUserById(userId: string): Promise<Array<Car>> {
        const array: Array<Car> = await adminGet(`users/${userId}/cars/`, {})
        return array.map(u => dtoToCar(u))
    }

    public async carsOfUser(user: User): Promise<Array<Car>> {
        return await this.carsOfUserById(user.id)
    }

    public async generateReport(car: Car): Promise<any> {
        return await adminDownload(`users/${car.owner}/cars/${car.id}/report`, `${car.plate} report.xlsx`)
    }

    public async updateCar(car: Car): Promise<Car> {
        const updated = await adminPut(`users/${car.owner}/cars/${car.id}`, car)
        return dtoToCar(updated)
    }

    public async createCar(car: Car): Promise<Car> {
        const updated = await adminPost(`users/${car.owner}/cars`, car)
        return dtoToCar(updated)
    }

    public async updateOrCreateCar(car: Car): Promise<Car> {
        if (car.id === "") {
            return this.createCar(car)
        } else {
            return this.updateCar(car)
        }
    }

    public async deleteCarById(id: String): Promise<any> {
        return await adminDelete("cars/" + id)
    }

    public async deleteCar(car: Car): Promise<any> {
        return await this.deleteCarById(car.id)
    }

    public async carsOfSelf(): Promise<Array<Car>> {
        return await this.carsOfUserById("self")
    }

    public async carsWithUpcoming(): Promise<Array<Car>> {
        return await adminGet("cars/upcoming")
    }

    public sortLocally(sort: Sort, cars: Array<Car>): Array<Car> {
        const comp = sortFuns[sort.field.toString()]
        if (comp === null) {
            return cars
        } else if (sort.direction === SortDir.ASC) {
            return cars.sort(comp)
        } else {
            return cars.sort((a: Car, b: Car) => comp(b, a))
        }
    }

}