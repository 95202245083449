<script setup lang="ts" generic="T">
import { computed } from 'vue';
import { Ok, Result } from '../../model/model';

import TableMessage from '../util/TableMessage.vue';

const props = defineProps<{
  result: Result<String, Array<T>> | Array<T>,
  cols: number,
  loadingMessage: string,
  emptyMessage: string,
  errorMessage: string,
  limitedMessage: string | null
}>()

let asResult = computed(() => {
  if (props.result instanceof Array) return new Ok(props.result)
  else return props.result
})
</script>

<template>
  <table class="main">
    <colgroup>
      <slot name="columns"></slot>
    </colgroup>
    <thead>
      <tr>
        <slot name="headers"></slot>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(entity, i) in (asResult as Ok<Array<T>>)?.data" v-bind:key="i" class="data-row" @click="$emit('entity-select', entity)">
        <slot name="entity" v-bind="entity"></slot>
      </tr>
      <TableMessage :result="asResult" :cols="cols" :loading-message="loadingMessage" :empty-message="emptyMessage"
        :error-message="errorMessage" :limited-message="limitedMessage"/>
    </tbody>
  </table>
</template>