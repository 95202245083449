"use strict";

import {adminGet} from "./http";

export default class SuggestionService {

    public async brands(): Promise<Array<String>> {
        return await adminGet(`suggestions/brand`)
    }

    public async works(): Promise<Array<String>> {
        return await adminGet(`suggestions/work`)
    }

    public async brandTypes(): Promise<Map<String, Array<String>>> {
        return await adminGet(`suggestions/type`)
    }
    
}