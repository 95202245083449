<script setup lang="ts">
import CarsListing from "../components/reactive/CarsListing.vue";
import {onMounted, ref} from "vue";
import {Car, Loading, Ok, Result, Sort} from "../model/model";
import CarService from '../services/CarService';
import {emitter} from "../main";
import {Message, MessageType} from "../components/global/MessageView.vue";
import router from "../router";

const carService = new CarService();

const loadedCars = ref<Result<String, Array<Car>>>(new Loading(""))

function fetch(sort: Sort | null) {
  carService.carsWithUpcoming()
      .then(cars => loadedCars.value = new Ok(cars))
      .catch(() => emitter.emit("log", new Message("car.load.fail", MessageType.Error)))
}

onMounted(() => fetch(null))

function carSelect(car: Car) {
  router.push({ name: "viewCar", params: {carId: car.id} });
}
</script>

<template>
  <main>
    <div id="title-container">
      <h1 id="title-label">{{ $t("global.upcoming") }}</h1>
    </div>
    <CarsListing :cars="loadedCars" @car-select="carSelect" :sortable="false" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

#title-label {
  font-weight: bold;
  cursor: pointer;
}
</style>
