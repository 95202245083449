<script lang="ts">
import router from '../router';
import CarService from '../services/CarService';
import UserService from '../services/UsersService';


import {Message, MessageType} from '../components/global/MessageView.vue';
import {CarWithWorks, MutableCar} from '../model/model';
import CarDetails from '../components/CarDetails.vue';

export const userService = new UserService();
export const carService = new CarService();

export default {
    props: {
      userId: {
        type: [String, null],
        default: null
      },
      carId: {
        type: [String, null],
        default: null
      }
    },
    data() {
      return {
        loadedCar: null,
        owner: null,
        headerLabel: this.carId ?  this.$t("car.loading") : this.$t("car.new")
      }
    },
    methods: {
      loadCar: async function () {
        if (this.carId) {
          try {
            this.loadedCar = await carService.fetchCarWithWorks(this.carId)
            this.headerLabel = this.loadedCar.car.plate
          } catch (e) {
            this.emitter.emit("log", new Message("car.loadFail", MessageType.Error))
          }  
        } else {
          this.loadedCar = new CarWithWorks(MutableCar.newFor(this.userId), [], [])
        }
        if (!this.userId && this.loadedCar) {
          await this.loadOwner(this.loadedCar.car.owner)
        }
      },
      loadOwner: async function (ownerId: string) {
        try {
          this.owner = await userService.fetchUser(ownerId)
        } catch (e) {
          this.emitter.emit("log", new Message("car.loadFail", MessageType.Error))
        }
      },
      backToCars() {
        router.back()
      },
      toUser() {
        if (this.owner) {
          router.push({ name: "viewUser", params: {userId: this.owner.id} });
        }
      },
      plateChanged(plate: String) {
        this.headerLabel = plate
      }
    },
    mounted() {
      if (this.userId) {
        this.loadOwner(this.userId)
      }
      this.loadCar()
    },
    components: { CarDetails }
}
</script>

<template>
  <main>
    <div id="title-container">
      <h1 class="title-type" @click="this.toUser()">{{ this.owner?.name ?? this.$t("user.loading") }}</h1>
      <h1>></h1>
      <h1 class="title-type" @click="this.backToCars()">{{ this.$t("global.cars") }}</h1>
      <h1>></h1>
      <h1>{{ this.headerLabel }}</h1>
    </div>
    <CarDetails v-if="this.loadedCar" :initial-car="this.loadedCar" @car-unselect="this.backToCars" @plate-change="this.plateChanged" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

.title-type {
  font-weight: bold;
  cursor: pointer;
}

h1#search-label {
  font-style: italic;
  margin-right: 1rem;
}

input {
  font-size: x-large;
  padding: 0.5rem;
  width: 500px;
  display: inline-block;
}
</style>
