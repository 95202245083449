import { ExpandingView } from "../model/model"

export function today(): String {
    const date = new Date()

    function padded(value: number) {
        return value < 10 ? `0${value}` : `${value}`
    }

    return `${date.getFullYear()}-${padded(date.getMonth() + 1)}-${padded(date.getDate())}`
}

export function asView<T>(entries: Array<T>): Array<ExpandingView<T>> {
    return entries.map(e => new ExpandingView<T>(e, false))
}