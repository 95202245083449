import { createRouter, createWebHistory } from 'vue-router'
import CarProfile from '../views/CarProfile.vue'
import CarsOverview from '../views/CarsOverview.vue'
import UserProfile from '../views/UserProfile.vue'
import UsersOverview from '../views/UsersOverview.vue'
import { credentialStore } from '../stores/credentials'
import LoginPanel from '../views/LoginPanel.vue'
import SettingsView from '../views/SettingsView.vue'
import HomeView from '../views/HomeView.vue'
import ProfileOverview from '../views/ProfileOverview.vue'
import ProfileCarOverview from '../views/ProfileCarOverview.vue'
import UpcomingOverview from "../views/UpcomingOverview.vue";

enum LoginRestriction {
  Admin, User, Any, None
}

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        loginRestriction: LoginRestriction.Any,
        title: "Mason & Steinberg | Home"
      },
    },
    {
      path: '/upcoming',
      name: 'upcoming',
      component: UpcomingOverview,
      props: true,
      meta: {
        loginRestriction: LoginRestriction.Admin,
        title: "Mason & Steinberg | Upcoming"
      }
    },
    {
      path: '/users',
      name: 'users',
      component: UsersOverview,
      meta: {
        loginRestriction: LoginRestriction.Admin,
        title: "Mason & Steinberg | Users"
      },
      props: true
    },
    {
      path: '/users/:userId',
      name: 'viewUser',
      component: UserProfile,
      meta: {
        loginRestriction: LoginRestriction.Admin,
      },
      props: true
    },
    {
      path: '/users/new',
      name: 'createUser',
      component: UserProfile,
      meta: {
        loginRestriction: LoginRestriction.Admin,
        title: "Mason & Steinberg | New user"
      }
    },
    {
      path: '/cars',
      name: 'cars',
      component: CarsOverview,
      props: true,
      meta: {
        loginRestriction: LoginRestriction.Admin,
        title: "Mason & Steinberg | Cars"
      }
    },
    {
      path: '/cars/:carId',
      name: 'viewCar',
      component: CarProfile,
      meta: {
        loginRestriction: LoginRestriction.Admin,
      },
      props: true
    },
    {
      path: '/cars/new/:userId',
      name: 'createCar',
      component: CarProfile,
      meta: {
        loginRestriction: LoginRestriction.Admin,
        title: "Mason & Steinberg | New car"
      },
      props: true
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: {
        loginRestriction: LoginRestriction.Admin,
        title: "Mason & Steinberg | Settings"
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPanel,
      meta: {
        loginRestriction: LoginRestriction.None,
        title: "Mason & Steinberg | Login"
      }
    },
    {
      path: '/login/:magicLink',
      name: 'linkLogin',
      component: LoginPanel,
      meta: {
        loginRestriction: LoginRestriction.None,
        title: "Mason & Steinberg | Login"
      },
      props: true
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileOverview,
      meta: {
        loginRestriction: LoginRestriction.User,
        title: "Mason & Steinberg | Users"
      }
    },
    {
      path: '/profile/cars/:carId',
      name: 'viewUserCar',
      component: ProfileCarOverview,
      meta: {
        loginRestriction: LoginRestriction.User,
      },
      props: true
    },
    {
      path: '/email_unsubscribe/:userId/:emailConsent',
      name: 'emailUnsubscribe',
      component: LoginPanel,
      meta: {
        loginRestriction: LoginRestriction.None,
        title: "Mason & Steinberg | Login"
      },
      props: true
    },
  ]
})

router.beforeEach((to) => {
  document.title = to.meta?.title as (string | null) ?? "Mason & Steinberg"

  const restriction = to.meta.loginRestriction ?? LoginRestriction.None
  
  if (restriction === LoginRestriction.Any) {
    if (!credentialStore().loggedIn) return '/login'
  } else if (restriction === LoginRestriction.Admin) {
    if (!credentialStore().isAdmin) return '/profile'
  } else if (restriction === LoginRestriction.User) {
    if (!credentialStore().isUser) return '/users'
  }
  
  if (to.name === "home") {
    if (credentialStore().isAdmin) {
      return '/upcoming'
    } else {
      return '/profile'
    }
  } else if (to.name === "login" || to.name === "linkLogin") {
    if (credentialStore().loggedIn) {
      if (!credentialStore().isAdmin) {
        return '/upcoming'
      } else {
        return '/profile'
      }
    }
  }
})

export default router
