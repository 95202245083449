"use strict";

import {adminGet, adminPost, adminPut} from "./http";
import type {Setting} from "../model/model";

export default class SettingsService {

    public async all(): Promise<Array<Setting>> {
        return await adminGet(`settings`)
    }

    public async refresh(): Promise<Array<Setting>> {
        return await adminPost(`settings/refresh`, {})
    }

    public async update(setting: Setting): Promise<Setting> {
        return await adminPut(`settings/${setting.name}`, setting.jsonData)
    }
    
}