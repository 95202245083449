class OptionValues {
    constructor(public readonly huName: String, public readonly enName: String) {}
}

class MaybeOptionValues {
    constructor(public readonly huName: String | null, public readonly enName: String | null) {}
}

const enSelector: (options: OptionValues) => String = ((options: OptionValues) => options.enName)
const huSelector: (options: OptionValues) => String = ((options: OptionValues) => options.huName)

function names(huName: String | null = null, enName: String | null = null): MaybeOptionValues {
    return new MaybeOptionValues(huName, enName)
}

export class CommonOptions {
    readonly localisedNames: Map<String, OptionValues>

    constructor(public readonly datalist: string, localisedNames: [string, MaybeOptionValues][]) {
        const filled: Array<[String, OptionValues]> = Array.from(localisedNames, ([key, o]) => [key, new OptionValues(o.huName ?? key, o.enName ?? key)])
        this.localisedNames = new Map(filled)
    }

    values(selector: (options: OptionValues) => String | null): String[] {
        return Array.from(this.localisedNames.values()).map(selector)
    }

    public enValues(): String[] {
        return this.values(enSelector);
    }

    public huValues(): String[] {
        return this.values(huSelector);
    }

    public localeValues(locale: 'hu' | 'en'): String[] {
        return locale === 'hu' ? this.huValues() : this.enValues()
    }

    localisedName(value: String, selector: (options: OptionValues) => String): String {
        const option = this.localisedNames.get(value)
        return option ? selector(option) : value
    }

    public huName(value: String): String {
        return this.localisedName(value, huSelector)
    }

    public enName(value: String): String {
        return this.localisedName(value, enSelector)
    }

    public localName(locale: 'hu' | 'en', value: String): String {
        return locale === 'hu' ? this.huName(value) : this.enName(value)
    }

    parse(value: String, selector: (options: OptionValues) => String): String {
        return Array.from(this.localisedNames.entries()).find(([_, options]) => selector(options) === value)?.[0] ?? value
    }

    public parseEn(value: String): String {
        return this.parse(value, enSelector);
    }

    public parseHu(value: String): String {
        return this.parse(value, huSelector);
    }

    public parseLocale(locale: 'hu' | 'en', value: String): String {
        return locale === 'hu' ? this.parseHu(value) : this.parseEn(value)
    }
}

export const commonTypes = new CommonOptions("type-datalist", [
    ["Cabriolet", names()],
    ["Estate", names("Kombi")],
    ["Sedan", names()],
    ["Sport", names()],
    ["Coupe", names()],
    ["Suv", names("SUV", "SUV")],
    ["Pickup", names()],
    ["Van", names("Furgon")],
    ["Hatchback", names("Ferdehátú")]
])

export const commonColors = new CommonOptions("color-datalist", [
    ["Black", names("Fekete")],
    ["Grey", names("Szürke")],
    ["Beige", names("Bézs")],
    ["Brown", names("Barna")],
    ["Gold", names("Arany")],
    ["Green", names("Zöld")],
    ["Red", names("Piros")],
    ["Silver", names("Ezüst")],
    ["White", names("Fehér")],
    ["Blue", names("Kék")],
    ["Yellow", names("Sárga")],
    ["Orange", names("Narancs")],
    ["Purple", names("Lila")],
    ["Metallic", names("Fém")],
])

export const commonFuel = new CommonOptions("fuel-datalist", [
    ["Petrol", names("Benzin")],
    ["Diesel", names("Dízel")],
    ["Gas", names("Gáz")],
    ["DieselHybrid", names("Dízel-hibrid", "Diesel-hybrid")],
    ["PetrolHybrid", names("Benzin-hibrid", "Petrol-hybrid")],
    ["Hydrogen", names("Hidrogén")],
    ["FullyElectric", names("Teljesen elektromos", "Fully electric")],
    ["Lpg", names("LPG", "LPG")],
])
