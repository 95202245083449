<script lang="ts">
import { OtpLogin } from '../../model/model';
import LoginService from '../../services/LoginService';
import { Message, MessageType } from '../global/MessageView.vue';

import LangSelector from './LangSelector.vue';

import { useReCaptcha } from 'vue-recaptcha-v3'

export const loginService = new LoginService();

export default {
    data: () => ({
        otpId: null,
        identifier: "",
        otpInput: ""
    }),
    methods: {
      async requestOtp() {
        try {
          const token = await this.recaptcha()
          this.otpId = await loginService.requestOtp(this.identifier, token);
        } catch (e) {
          this.emitter.emit("log", new Message("login.failInit", MessageType.Error))
        }
      },
      login() {
          this.$emit("trigger-login", new OtpLogin(this.otpId, this.otpInput));
      }
    },
    setup() {
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

      const recaptcha = async () => {
        await recaptchaLoaded()
        return await executeRecaptcha('submit')
      }

      return {
        recaptcha
      }
    },
    components: { LangSelector }
}
</script>

<template>
  <div id="user-login">
    <div v-if="!otpId">
      <label for="username-input">{{ $t("login.emailOrPhone") }}:</label>
      <input id="username-input" v-model="identifier" :placeholder="$t('login.emailOrPhone')" type="text"
             v-on:keyup.enter="requestOtp" autofocus autocomplete="email tel">
      <div>
        <button @click="requestOtp">{{ $t("login.login") }}</button>
        <LangSelector />
      </div>
    </div>
    <div v-if="otpId">
      <div id="otp-label">{{ $t("login.otpSent") }}</div>
      <label for="otp-input">{{ $t("login.otp") }}:</label>
      <input id="otp-input" v-model="otpInput" type="text" :placeholder="$t('login.otpPlaceholder')"
             v-on:keyup.enter="login" autocomplete="one-time-code">
      <div>
        <button @click="login">{{ $t("login.login") }}</button>
        <LangSelector />
      </div>
    </div>
  </div>
</template>

<style scoped>
#user-login {
  padding-left: 1rem;
  padding-right: 1rem;
}

#user-login>div>div {
  margin-top: 0.5rem;
}

input {
  display: block;
  width: 100%;
  padding: 0.5rem;
}

button {
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: var(--global-color-background);
  cursor: pointer;
}

#otp-label {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 2px solid var(--global-color-background);
}
</style>
