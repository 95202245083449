<script setup lang="ts">

import {User} from '../../model/model';

const props = defineProps<{
  user: User
}>()

function initCall() {
  if (props.user.phone) {
    window.open(`tel:${props.user.phone}`)
  }
}

function initEmail() {
  if (props.user.email) {
    window.open(`mailto:${props.user.email}`)
  }
}
</script>

<template>
  <div class="card">
    <div class="user-name-view">{{ user.name }}</div>
    <div><font-awesome-icon class="nav-icon" icon="phone" @click.stop="initCall" /><span>{{ user.phone ?? '-' }}</span></div>
    <div><font-awesome-icon class="nav-icon" icon="envelope" @click.stop="initEmail" /><span>{{ user?.email ?? '-' }}</span></div>
  </div>  
</template>

<style scoped>
.user-name-view {
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.card>div:not(.user-name-view)>span {
  padding-left: 1rem;
}

.card>div:not(.user-name-view) {
  padding-left: 0.5rem;
}
</style>