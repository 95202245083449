<script setup lang="ts" generic="T">
import { computed } from 'vue';
import { ExpandingView, Ok } from '../../model/model';

import TableMessage from '../util/TableMessage.vue';

const props = defineProps<{
  entities: Array<ExpandingView<T>>,
  cols: number,
  loadingMessage: string,
  emptyMessage: string,
  errorMessage: string
}>()

let asResult = computed(() => new Ok(props.entities))
</script>

<template>
  <table class="main">
    <colgroup>
      <slot name="columns"></slot>
    </colgroup>
    <thead>
      <tr>
        <slot name="headers"></slot>
      </tr>
    </thead>
    <tbody>
      <template v-for="(e, i) in props.entities" v-bind:key="i" >
        <tr :id="'row-' + e.data['id']" class="data-row" @click="e.toggle()" :class="e.expanded ? 'selected' : ''">
          <slot name="entity" v-bind="e.data"></slot>
        </tr>
        <tr :id="'row-' + e.data['id'] + '-expanded'" v-if="e.expanded" class="nested-row">
          <td :colspan="cols">
            <slot name="details" v-bind="e.data"></slot>
          </td>
        </tr>
      </template>
      <TableMessage :result="asResult" :cols="cols" :loading-message="loadingMessage" :empty-message="emptyMessage" :error-message="errorMessage" />
      <slot name="footer"></slot>
    </tbody>
  </table>
</template>