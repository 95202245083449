export const messages = {
    en: {
        message: {
            user: {
                created: {
                    ok: "New user successfully created",
                    fail: "Failed to create user"
                },
                updated: {
                    ok: "User successfully updated",
                    fail: "Failed to update user data"
                },
                deleted: {
                    confirm: "Sure you want to delete user {name}?",
                    ok: "User successfully deleted",
                    fail: "Failed to delete user"
                },
                consent: {
                    present: "The user is allowed to receive emails",
                    create: "Enable",
                    created: "Email communication enabled for user",
                    createFailed: "Failed to setup email communication for user",
                    missing: "The user is NOT allowed to receive emails",
                    delete: "Disable",
                    deleted: "Email communication disabled for user",
                    deleteFailed: "Failed to disable email communication for user"
                }
            },
            car: {
                created: {
                    ok: "New car successfully created",
                    fail: "Failed to create car"
                },
                updated: {
                    ok: "Car successfully updated",
                    fail: "Failed to update car data"
                },
                deleted: {
                    confirm: "Sure you want to delete car {plate}?",
                    ok: "Car successfully deleted",
                    fail: "Failed to delete car"
                }
            },
            work: {
                created: {
                    ok: "Work entry successfully logged",
                    fail: "Failed to log work entry"
                },
                deleted: {
                    confirm: "Sure to delete work entry?",
                    ok: "Work entry successsfully deleted",
                    fail: "Failed to delete work entry"
                }
            },
            suggestedWork: {
                deleted: {
                    confirm: "Sure to delete suggested entry?"
                }
            },
            auth: {
                expired: "Session expired, login required",
                error: "Forbidden, login required"
            }
        },
        global: {
            upcoming: "Upcoming",
            users: "Users",
            cars: "Cars",
            profile: "Profile",
            logout: "Logout",
            search: "Search"
        },
        user: {
            details: {
                name: "Name",
                email: "Email",
                phone: "Phone",
            },
            search: {
                placeholder: "Name, email, phone, etc"
            },
            cars: "Cars of user",
            load: {
                inProgress: "Loagin users",
                fail: "Failed to load users",
                empty: "No users found",
                limited: "Limited number of users shown"
            },
            loading: "Loading user",
            loadFail: "Failed to load user data",
            new: "New user",
            action: {
                new: "Create user",
                delete: "Delete user",
                edit: "Edit user data",
                undo: "Revert user data",
                save: "Save user",
                newCar: "Add new car"
            }
        },
        car: {
            details: {
                plate: "Plate",
                brand: "Brand",
                model: "Model",
                type: "Type",
                color: "Color",
                year: "Year",
                displacementQcm: "Displacement (qcm)",
                fuel: "Fuel",
                vin: "Vin",
                motorNum: "Motor number",
                licenseExp: "License expiry",
                nextService: "Next service",
                worksheet: "Worksheet",
                performanceKw: "Performance (kW)"
            },
            search: {
                placeholder: "Plate, type, vin, etc"
            },
            works: "Works on car",
            suggestions: "Suggested works for car",
            reportFail: "Failed to download worksheet",
            generateReport: "Download",
            load: {
                inProgress: "Loading cars",
                fail: "Failed to load cars",
                empty: "No cars found",
                limited: "Limited number of cars shown"
            },
            loading: "Loading car",
            loadFail: "Failed to load car data",
            new: "New car",
            action: {
                new: "Create car",
                delete: "Delete car",
                edit: "Edit car data",
                undo: "Revert car data",
                save: "Save car",
                newWork: "Log work",
                newSuggestion: "Add new suggestion",
                saveWork: "Save entry",
                undoWork: "Revert entry",
                generateReport: "Download worksheet"
            }
        },
        work: {
            details: {
                date: "Date",
                name: "Name",
                kms: "Kilometers",
                components: "Components",
                suggested: "Suggested works"
            },
            delete: "Delete entry",
            update: "Modify entry",
            load: {
                inProgress: "Loading work entries",
                fail: "Failed to load work entries",
                empty: "No work entry found"
            }
        },
        suggestedWork: {
            load: {
                inProgress: "Loading suggested works",
                fail: "Failed to load suggested work entries",
                empty: "No suggested work entry found"
            },
            action: {
                logSuggestionDone: "Add as completed"
            }
        },
        modal: {
            cancel: "Cancel",
            confirm: "Confirm",
            delete: "Delete",
            message: {
                logout: "Are you sure you want to logout?"
            }
        },
        login: {
            userLogin: "User login",
            adminLogin: "Admin login",
            emailOrPhone: "Email or phone",
            username: "Username",
            password: "Password",
            login: "Login",
            otpSent: "We've sent you an email containing your login code if the account exists",
            otp: "Received code",
            otpPlaceholder: "6 digit code",
            invalid: "Invalid login credentials",
            failInit: "Failed to initialise login"
        },
        email: {
            unsubscribe: {
                ok: "Successfully unsubscribed from any email communications",
                fail: "Error occured while trying to unsubscribe from emails, possible you have already unsubscribed",
            }
        },
        settings: {
            settings: "Settings",
            storedSettings: "DB entries",
            dbRefresh: "Refresh from DB",
            fail: "Failed to load settings",
            save: "Save to DB",
            saveFail: "Failed to save settings"
        },
        util: {
            showMore: "Show more",
            showLess: "Show less"
        }
    },
    hu: {
        message: {
            user: {
                created: {
                    ok: "Új ügyfél sikeresen létrehozva",
                    fail: "Nem sikerült létrehozni az ügyfelet"
                },
                updated: {
                    ok: "Ügyfél adatok sikeresen frissítve",
                    fail: "Nem sikerült megváltoztatni az ügyfél adatokat"
                },
                deleted: {
                    confirm: "Biztos hogy törlöd {name} ügyfelet?",
                    ok: "Ügyfél sikeresen törölve",
                    fail: "Nem sikerült törölni az ügyfelet"
                },
                consent: {
                    present: "Az e-mail küldés engedélyezve van az ügyfélnek",
                    create: "Engedélyezés",
                    created: "E-mail küldés engedélyezve az ügyfélnek",
                    createFailed: "Nem sikerült engedélyezni az e-mail küldést az ügyfélnek",
                    missing: "Jelenleg az ügyfél nem kaphat e-maileket",
                    delete: "Letiltás",
                    deleted: "E-mail küldés letiltva az ügyfélnek",
                    deleteFailed: "Nem sikerült letiltani az e-mail küldést az ügyfélnek"
                }
            },
            car: {
                created: {
                    ok: "Új autó sikeresen létrehozva",
                    fail: "Nem sikerült létrehozni az autót"
                },
                updated: {
                    ok: "Autó adatok sikeresen frissítve",
                    fail: "Nem sikerült megváltoztatni az autó adatokat"
                },
                deleted: {
                    confirm: "Biztos hogy törlöd a {plate} rendszámú autót?",
                    ok: "Autó sikeresen törölve",
                    fail: "Nem sikerült törölni az autót"
                }
            },
            work: {
                created: {
                    ok: "Elvégzett munka feljegyezve",
                    fail: "Nem sikerült feljegyezni a munka bejegyzést"
                },
                deleted: {
                    confirm: "Biztos hogy törlöd a munka bejegyzést?",
                    ok: "Munka bejegyzés sikeresen törölve",
                    fail: "Nem sikerült törölni a bejegyzést"
                }
            },
            suggestedWork: {
                deleted: {
                    confirm: "Biztos hogy törlöd a javasolt munka bejegyzést?"
                }
            },
            auth: {
                expired: "Lejárt munkamenet, belépés szükséges",
                error: "Elérés megtagadva, belépés szükséges"
            }
        },
        global: {
            upcoming: "Aktualitások",
            users: "Ügyfelek",
            cars: "Autók",
            profile: "Profil",
            logout: "Kijelentkezés",
            search: "Keresés"
        },
        user: {
            details: {
                name: "Név",
                email: "Email",
                phone: "Telefon",
            },
            search: {
                placeholder: "Név, email, telefon, stb"
            },
            cars: "Ügyfél autói",
            load: {
                inProgress: "Ügyfelek betöltése folyamatban",
                fail: "Nem sikerült betölteni az ügyfeleket",
                empty: "Nem található ügyfél",
                limited: "Korlátozott számú ügyfél betöltve"
            },
            loading: "Ügyfél betöltése",
            loadFail: "Nem sikerült betölteni az ügyfél adatait",
            new: "Új ügyfél",
            action: {
                new: "Új ügyfél",
                delete: "Ügyfél törlése",
                edit: "Ügyfél módosítása",
                undo: "Ügyfél visszaállítása",
                save: "Ügyfél mentése",
                newCar: "Új autó hozzáadása"
            }
        },
        car: {
            details: {
                plate: "Rendszám",
                brand: "Márka",
                model: "Model",
                type: "Kivitel",
                vin: "Alvázszám",
                color: "Szín",
                year: "Évjárat",
                displacementQcm: "Henger űr. (ccm)",
                fuel: "Üzemanyag",
                motorNum: "Motor azonosító",
                licenseExp: "Forgalmi érvényesség",
                nextService: "Következő szervíz",
                worksheet: "Munkalap",
                performanceKw: "Teljesítmény (kW)"
            },
            search: {
                placeholder: "Rendszám, model, alváz, stb"
            },
            works: "Feljegyzett munkák",
            suggestions: "Javasolt munkák",
            reportFail: "Nem sikerült lekérni a munkalapot",
            generateReport: "Letöltés",
            load: {
                inProgress: "Autók betöltése folyamatban",
                fail: "Nem sikerült betölteni az autókat",
                empty: "Nem található autó",
                limited: "Korlátozott számú autó betöltve"
            },
            loading: "Autó betöltése",
            loadFail: "Nem sikerült betölteni az autó adatait",
            new: "Új autó",
            action: {
                new: "Új autó",
                delete: "Autó törlése",
                edit: "Autó szerkesztése",
                undo: "Autó visszaállítása",
                save: "Autó mentése",
                newWork: "Új munka feljegyzése",
                newSuggestion: "Új javaslat felvétele",
                saveWork: "Feljegyzés mentése",
                undoWork: "Feljegyzés visszavonása",
                generateReport: "Munkalap letöltése"
            }
        },
        work: {
            details: {
                date: "Dátum",
                name: "Megnevezés",
                kms: "Kilóméteróra állás",
                components: "Alkatrészek",
                suggested: "Javasolt munkák"
            },
            delete: "Bejegyzés törlése",
            update: "Bejegyzés módosítása",
            load: {
                inProgress: "Munkalap betöltése folyamatban",
                fail: "Nem sikerült betölteni a munkalapot",
                empty: "Nem található munka bejegyzés"
            }
        },
        suggestedWork: {
            load: {
                inProgress: "Javasolt munkák betöltése folyamatban",
                fail: "Nem sikerült betölteni a javasolt munkákat",
                empty: "Nem található javasolt munka bejegyzés"
            },
            action: {
                logSuggestionDone: "Felvétel a munkalapra"
            }
        },
        modal: {
            cancel: "Mégsem",
            confirm: "Megerősítés",
            delete: "Törlés",
            message: {
                logout: "Biztos hogy kijelentkezel?"
            }
        },
        login: {
            userLogin: "Ügyfelek",
            adminLogin: "Munkatársak",
            emailOrPhone: "Email vagy telefonszám",
            username: "Felhasználónév",
            password: "Jelszó",
            login: "Bejelentkezés",
            otpSent: "Amennyiben az ügyfél fiók létezik, elküldtük a belépési kódot a fiókhoz tartozó email címre",
            otp: "Kapott kód",
            otpPlaceholder: "6 jegyű kód",
            invalid: "Hibás belépési azonosítók",
            failInit: "Nem sikerült elindítani a bejelentkezést"
        },
        email: {
            unsubscribe: {
                ok: "Sikeresen leiratkozott minden email kommunikációról",
                fail: "Hiba a leiratkozás közben, lehetséges hogy már korábban leiratkozott",
            }
        },
        settings: {
            settings: "Beállítások",
            storedSettings: "DB bejegyzések",
            dbRefresh: "Újratöltés az adatbázisból",
            fail: "Nem sikerült betölteni a beállításokat",
            save: "Mentés adatbázisba",
            saveFail: "Nem sikerült elmenteni a beállítást"
        },
        util: {
            showMore: "Mutass tőbbet",
            showLess: "Mutass kevesebbet"
        }
    }
}