<script lang="ts">
import { uuid } from 'vue-uuid';
import { credentialStore } from '../../stores/credentials';

export enum MessageType {
  Success = "success", Info = "info", Error = "error"
}

export class Message {
  constructor(public readonly message: String, public readonly type: MessageType) {}
}

const logMethod = {
  [MessageType.Success]: (str: String) => console.log(str),
  [MessageType.Info]: (str: String) => console.warn(str),
  [MessageType.Error]: (str: String) => console.error(str)
}

export default {
  data() {
    return {
      shown: null,
      message: null,
      loggedIn: false
    }
  },
  methods: {
    show(message: Message) {
      logMethod[message.type](message.message)
      const id = uuid.v4()
      this.message = message
      this.shown = id
      this.loggedIn = credentialStore().loggedIn
      new Promise(function(resolve) {
        setTimeout(resolve, 4000)
      }).finally(() => {
        if (this.shown === id) {
          this.shown = null
          this.message = null
        }
      })
    }
  },
  created() {
    this.emitter.on("log", (m: Message) => this.show(m))
  }
}
</script>

<template>
  <Transition>
    <div id="message-bar" :class="this.message.type + (this.loggedIn ? ' ' : ' full-width')" v-if="this.message">
      {{ $t(message.message) }}
    </div>
  </Transition>
</template>

<style>
#message-bar {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  width: calc(100% - 250px);
  text-align: center;
  left: 250px;
  font-size: 16px;
  margin-left: 0;
  font-weight: bold;
  color: white;
}

#message-bar.full-width {
  width: 100%;
  left: 0;
}

#message-bar.success {
  background-color: seagreen;
}

#message-bar.info {
  background-color: rgb(205, 174, 0);
}

#message-bar.error {
  background-color: crimson;
}

@media (max-width:480px)  {
  #message-bar {
    width: 100%;
    left: 0;
  }
}
</style>
