<script setup lang="ts">
import {ExpandingView, MutableSuggestedWork, SuggestedWork, ValidationError, Work} from '../../model/model';

import {Message, MessageType} from '../global/MessageView.vue';

import ConfirmService, {DialogMessage} from '../../services/ConfirmService';

import {Ref, ref} from 'vue';
import {emitter} from '../../main';
import SuggestedWorkService from '../../services/SuggestedWorkService';
import {asView} from '../../util/data';
import {clearErrorAt, setErrorAt} from '../../util/validationErrors';
import SuggestedWorksListing from "../reactive/SuggestedWorksListing.vue";

const suggestionsService = new SuggestedWorkService()
const confirmService = new ConfirmService(emitter)

const props = defineProps<{
  carId: string,
  suggestions: Array<SuggestedWork> | null,
  editEnabled: Boolean
}>()

const expandableSuggestions = ref(asView(props.suggestions ?? []))
const createdSuggestion: Ref<MutableSuggestedWork | null> = ref(null)

function clearError(id: String | null) {
  const rowId = id ? `row-${id}-expanded` : 'new-row'
  clearErrorAt(`#suggestions-data #${rowId}`)
}

function setError(id: String | null, error: ValidationError) {
  const rowId = id ? `row-${id}-expanded` : 'new-row'
  setErrorAt(`#suggestions-data #${rowId}`, 'td', error)
}

function addNew() {
  createdSuggestion.value = MutableSuggestedWork.newFor(props.carId)
}

function cancelNew() {
  createdSuggestion.value = null
  clearError(null)
}

async function saveNew() {
  const toCreate = createdSuggestion.value
  if (toCreate !== null) {
    clearError(null)
    try {
      const newSuggestion = await suggestionsService.createSuggestion(toCreate)
      expandableSuggestions.value.push(new ExpandingView<SuggestedWork>(newSuggestion, false))
      createdSuggestion.value = null
      emitter.emit("log", new Message("message.work.created.ok", MessageType.Success))
    } catch (e) {
      if (e instanceof ValidationError) {
        setError(null, e as ValidationError)
      } else {
        clearError(null)
      }

      console.log(e)
      emitter.emit("log", new Message("message.work.created.fail", MessageType.Error))
    }
  }
}

function confirmSuggestionDel(workId: String) {
  confirmService.askToConfirm(
    new DialogMessage("message.suggestedWork.deleted.confirm", {}, "modal.cancel", "modal.delete"),
    () => delSuggestion(workId)
  )
}

function delSuggestion(workId: String) {
  suggestionsService.deleteSuggestionById(workId)
    .then(() => {
      const index = expandableSuggestions.value.findIndex((w: ExpandingView<Work>) => w.data.id === workId)
      if (index >= 0) {
        expandableSuggestions.value.splice(index, 1)
      }
      emitter.emit("log", new Message("message.work.deleted.ok", MessageType.Success))
    })
    .catch(() => emitter.emit("log", new Message("message.work.deleted.fail", MessageType.Error)))
}

function confirmEdit(suggested: SuggestedWork) {
  edit(suggested)
}

async function edit(suggested: SuggestedWork) {
  clearError(suggested.id)
  try {
    const updated = await suggestionsService.updateSuggestion(suggested)
    const index = expandableSuggestions.value.findIndex((s: ExpandingView<SuggestedWork>) => s.data.id === suggested.id)
    if (index >= 0) {
      expandableSuggestions.value.at(index).data = updated
    }
    emitter.emit("log", new Message("message.work.created.ok", MessageType.Success))
  } catch (error) {
    if (error instanceof ValidationError) {
      setError(suggested.id, error as ValidationError)
    } else {
      clearError(suggested.id)
    }
    emitter.emit("log", new Message("message.work.created.fail", MessageType.Error))
  }
}
</script>

<template>
  <h1>{{ $t("car.suggestions") }}</h1>
  <SuggestedWorksListing :edit-enabled="!!editEnabled" :suggestions="expandableSuggestions" :created-work="createdSuggestion"
                         @delete-work="confirmSuggestionDel"  @edit-work="confirmEdit" @complete-suggested="(s) => $emit('complete-suggested', s)" />
  <button class="work-button action-button" v-if="editEnabled && !createdSuggestion" @click="addNew" :title="$t('car.action.newSuggestion')"><font-awesome-icon class="nav-icon" icon="plus-circle" /></button>
  <button class="work-button action-button" v-if="createdSuggestion" @click="saveNew" :title="$t('car.action.saveWork')"><font-awesome-icon class="nav-icon" icon="floppy-disk" /></button>
  <button class="work-button action-button" v-if="createdSuggestion" @click="cancelNew" :title="$t('car.action.undoWork')"><font-awesome-icon class="nav-icon" icon="clock-rotate-left" /></button>
</template>

<style scoped>
th, td {
  font-size: large;
}

#works-data {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
</style>