<script lang="ts">

import { Dialog, DialogMessage } from '../../services/ConfirmService'

export default {
  data() {
    return {
      message: null as Dialog | null
    }
  },
  created() {
    this.emitter.on('confirm-request', (m: Dialog) => this.message = m)
  },
  methods: {
    confirm() {
      this.respond(true)
    },
    cancel() {
      this.respond(false)
    },
    respond(confirm: Boolean) {
      const message = this.message
      this.message = null
      this.emitter.emit(confirm ? 'confirm-ok' : 'confirm-cancel', message.id)
    }
  }
}
</script>

<template>
  <div id="dialog-holder" v-if="message" @click.stop="cancel">
    <div id="dialog-body" @click.stop="">
      <div id="dialog-message">{{ $t(message.data.message, message.data.props) }}</div>    
      <div id="dialog-buttons">
        <button id="cancel-btn" @click="cancel">{{ $t(message.data.cancelButton) }}</button>
        <button id="confirm-btn" @click="confirm">{{ $t(message.data.confirmButton) }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#dialog-holder {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(0,0,0,0.4);
}

#dialog-body {
  margin: auto;
  display: inline-block;
  background-color: var(--vt-c-white-soft);
  color: var(--global-color-background);
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: x-large;
}

#dialog-message {
  padding-bottom: 1rem;
}

#dialog-buttons {
  text-align: right;
}

#dialog-body button {
  font-size: large;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
}

#dialog-body #confirm-btn {
  background-color: crimson;
  color: white;
  border: 1px solid black;
  margin-left: 1rem;
}

@media (max-width:480px)  {
  #dialog-body {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
