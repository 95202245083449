<script lang="ts">
import {Car, Fail, Loading, Ok, Result} from '../model/model';
import router from '../router';
import CarService from '../services/CarService';

import {Message, MessageType} from '../components/global/MessageView.vue';
import CarsTable from '../components/table/CarsTable.vue';
import {credentialStore} from '../stores/credentials';
import CarCard from "../components/card/CarCard.vue";
import ReactiveItem from "../components/util/ReactiveItem.vue";
import CarsListing from "../components/reactive/CarsListing.vue";

export const carService = new CarService();

export default {
    data() {
      return {
        name: credentialStore().name,
        loadedCars: new Ok([]) as Result<String, Array<Car>>
      }
    },
    methods: {
      async loadCars() {
        this.loadedCars = new Loading("")
        
        try {
          const result = await carService.carsOfSelf()
          this.loadedCars = new Ok(result)
        } catch (e) {
          this.emitter.emit("log", new Message("car.load.fail", MessageType.Error))
          this.loadedCars = new Fail(e)
        }
      },
      carSelect: function(car: Car) {
        router.push({ name: "viewUserCar", params: {carId: car.id} });
      }
    },
    mounted() {
      this.loadCars()
    },
    components: {CarsListing, ReactiveItem, CarCard, CarsTable }
}
</script>

<template>
  <main>
    <div id="title-container">
      <h1 id="title-label">{{ name }}</h1>
    </div>
    <CarsListing :cars="this.loadedCars" @car-select="carSelect" :sortable="false" />
  </main>
</template>

<style scoped>
#title-container {
  margin-bottom: 1rem;
}

#title-container>div {
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0.5rem;
}

#title-label {
  font-weight: bold;
  cursor: pointer;
}
</style>
