<script setup lang="ts">
import SettingsService from "../services/SettingsService";
import {onMounted, ref} from "vue";
import {emitter} from "../main";
import {Message, MessageType} from "./global/MessageView.vue";
import type {Setting} from "../model/model";
import {ExpandingView} from "../model/model";
import VueJsonEditor from "vue3-ts-jsoneditor";

const settingsService = new SettingsService()

const settings = ref<Array<ExpandingView<Setting>>>([])

function showLoadFail(e: any) {
  console.error("Failed to load settings", e)
  emitter.emit("log", new Message("settings.fail", MessageType.Error))
}

function onLoaded(data: Array<Setting>) {
  settings.value = data.map(setting => new ExpandingView(setting, false))
}

onMounted(() => {
  settingsService.all()
      .then(onLoaded)
      .catch(showLoadFail)
})

async function triggerRefresh() {
  try {
    onLoaded(await settingsService.refresh())
  } catch (e) {
    showLoadFail(e)
  }
}

async function saveToDb(setting: Setting) {
  try {
    await settingsService.update(setting)
  } catch (e) {
    console.error("Failed to overwrite setting", e)
    emitter.emit("log", new Message("settings.saveFail", MessageType.Error))
  }
}
</script>

<template>
  <h1>{{ $t("settings.storedSettings") }}</h1>
  <button @click="triggerRefresh">{{ $t("settings.dbRefresh") }}</button>
  <ul>
    <li v-for="(e) in settings">
      <span @click="e.toggle()">{{ e.expanded ? '-' : '+' }} {{ e.data.name }}</span>
      <div v-if="e.expanded">
        <button @click="saveToDb(e.data)">{{ $t("settings.save") }}</button>
        <VueJsonEditor v-model:jsonString="e.data.jsonData" />
      </div>
    </li>
  </ul>
</template>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0.2rem 0;

  font-family: monospace;
  font-size: x-large;
  cursor: pointer;
}
</style>