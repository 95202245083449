"use strict";

import { dtoToWork } from "../model/dto";
import { Work } from "../model/model";
import { adminDelete, adminPost, adminPut } from "./http";

export default class WorkService {

    public async updateWork(work: Work): Promise<Work> {
        const updated = await adminPut(`cars/${work.car}/works/${work.id}`, work)
        return dtoToWork(updated)
    }

    public async createWork(work: Work): Promise<Work> {
        const updated = await adminPost(`cars/${work.car}/works/`, work)
        return dtoToWork(updated)
    }

    public async updateOrCreateWork(work: Work): Promise<Work> {
        if (work.id === "") {
            return this.createWork(work)
        } else {
            return this.updateWork(work)
        }
    }

    public async deleteWorkById(id: String): Promise<any> {
        return await adminDelete(`works/${id}`)
    }

    public async deleteWork(work: Work): Promise<any> {
        return await this.deleteWorkById(work.id)
    }

}