<script lang="ts">
import {Ok, Result, User} from '../../model/model';
import type {PropType} from "vue";

export default {
    props: {
        result: {
          type: [Object as () => Result<String, any>]
        },
        cols: Number,
        loadingMessage: String,
        errorMessage: String,
        emptyMessage: String,
        limitedMessage: null as unknown as PropType<String | null>
    },
    computed: {
      shown() {
        return !!this.message
      },
      message(): String {
        if (this.result.isFail()) {
          return this.errorMessage
        } else if (this.result.isLoading()) {
          return this.loadingMessage
        } else {
          const okResult = this.result as Ok<Array<User>>
          if (okResult?.data.length === 0) {
            return this.emptyMessage
          } else if (okResult?.limited) {
            return this.limitedMessage
          } else {
            return null
          }
        }
      }
    }
}
</script>

<template>
  <tr v-if="shown" class="mesage-row"><td :colspan="cols" class="center">{{ $t(this.message) }}</td></tr>
</template>

<style scoped>
tr.mesage-row>td {
  font-weight: bold;
}
</style>
